import styled, { css } from "styled-components";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import Container from "../../../../../../../common-deprecated/styles/v2/lexus/globals/Container";
import { Ellipsis } from "../../../../../../../common-deprecated/styles/v2/lexus/utilities/Ellipsis";
import { breakpoints } from "../../../../../../shared-logic/themes/lexusTheme";

// Because of the way the isFixed logic works we need to know the height of the filter static bar.
// If this ever needs to be dynamic this needs to be calculated with React refs.
export const FILTER_BAR_HEIGHT = 52;

export const Border = styled.div`
    display: none;

    @media screen and (min-width: ${breakpoints.lg}) {
        display: block;
        position: sticky;
        top: var(--visibleHeaderHeight, 0);
        scroll-margin-top: var(--visibleHeaderHeight, 0);
        border-bottom: 1px solid ${theme.colors.platinumLight};
        z-index: ${theme.zIndex.fixed - 1}; /* z-index should be 1 lower then primary navigation */
    }

    .primary-hidden > & {
        top: 0 !important;
    }
`;

export const Wrapper = styled(Container)<{ isFixed?: boolean; savedCarsEnabled?: boolean }>`
    display: flex;
    align-items: center;
    padding-block: 0;
    padding-inline: ${theme.space[3]}px !important; /* override lexus CSS */

    @supports not (padding-inline: 0) {
        padding: 0 ${theme.space[3]}px !important;
    }

    /* vw = 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        padding-inline: ${theme.space[3]}px !important;

        @supports not (padding-inline: 0) {
            padding-left: ${theme.space[3]}px !important;
            padding-right: ${theme.space[3]}px !important;
        }
    }

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: ${theme.colors.mist};
    }
`;

export const Inner = styled.div<{ isFixed: boolean }>`
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.space[2]}px;
    width: 100%;
    max-height: 100%;
    padding: ${theme.space[3]}px 0;
    z-index: 10;

    @media screen and (min-width: ${breakpoints.lg}) {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: ${theme.space[3]}px;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: ${theme.space[4]}px;
    }

    @supports not (gap: 0) {
        grid-column-gap: ${theme.space[2]}px;
        grid-row-gap: ${theme.space[2]}px;
    }
`;

export const FilterResultCount = styled.div`
    text-align: center;

    @media screen and (min-width: ${breakpoints.xl}) {
        grid-column: span 2;
    }
`;

export const FilterIcon = styled(IconWrapper)<{ showFilters: boolean }>`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    gap: ${theme.space[2]}px;
    width: 100%;

    & > div,
    & > button {
        width: 50%;
    }

    @media screen and (min-width: ${breakpoints.md}) {
        flex-grow: 0;
        justify-content: flex-end;
        width: auto;

        & > div,
        & > button {
            width: auto;
        }
    }

    @supports not (gap: 0) {
        &:not(:only-child):not(:first-child) {
            & > * {
                margin-${getDirection("left")}: ${theme.space[2]}px;
            }
        }
    }
`;

const lineClampStyle = css`
    /* stylelint-disable */
    display: -webkit-box;
    text-align: start;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow: hidden;
    hyphens: auto;
    white-space: normal;
    /* stylelint-enable */
`;

const lineClampReset = css`
    /* stylelint-disable */
    display: inline-block;
    max-width: 100%;
    line-clamp: none;
    -webkit-line-clamp: none;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    /* stylelint-enable */
`;

export const Button = styled.button<{ hasIconRight?: boolean; isActive?: boolean }>`
    display: inline-flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${theme.space[1]}px;
    font-family: ${theme.fonts.book};
    font-size: 1.8rem;
    line-height: 2rem;
    color: ${theme.colors.midnight};
    background-color: ${({ isActive }) => (isActive ? theme.colors.white : theme.colors.mist)};
    border: 1px solid ${theme.colors.titanium};
    border-radius: 4px;

    & > div {
        display: flex;
        flex-grow: 1;
        gap: ${theme.space[3] / 2}px;
    }

    ${Ellipsis} {
        ${lineClampStyle};
    }

    :hover {
        background-color: ${theme.colors.white};
    }

    @media screen and (min-width: ${breakpoints.lg}) {
        justify-content: ${({ hasIconRight }) => (hasIconRight ? "flex-end" : "flex-start")};
        ${Ellipsis} {
            ${lineClampReset};
        }
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        ${Ellipsis} {
            ${lineClampStyle};
        }
    }

    @media screen and (min-width: ${breakpoints.xxl}) {
        ${Ellipsis} {
            ${lineClampReset};
        }
    }

    @supports not (gap: 0) {
        & > ${IconWrapper} {
            ${({ hasIconRight }) =>
                hasIconRight
                    ? css`
                margin-${getDirection("left")}: ${theme.space[3] / 2}px;
            `
                    : css`
                margin-${getDirection("right")}: ${theme.space[3] / 2}px;
            `}
        }
    }
`;
