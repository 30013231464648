import styled, { css } from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Container = styled.div.attrs(() => ({
    className: "container",
}))<{ noContainerSpacing: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1600px;
    padding-inline: ${theme.space[3]}px !important;
    transition: none;

    @supports not (padding-inline: 0) {
        padding-left: ${theme.space[3]}px !important;
        padding-right: ${theme.space[3]}px !important;
    }

    ${({ noContainerSpacing }) =>
        noContainerSpacing &&
        css`
            padding-inline: 0 !important;
        `};
`;
