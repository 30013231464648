import styled, { css, keyframes } from "styled-components";
import {
    DisplayProps,
    MinWidthProps,
    position,
    space,
    SpaceProps,
    typography,
    TypographyProps,
    width,
    WidthProps,
} from "styled-system";
import { BreakPointValuesType, getBreakpoint, getDirection, ThemeTypedColorProps } from "../../themes/common";
import { IconAnimatedToggleArrowStyle } from "./AnimatedStyles";
import IconWrapper from "./IconWrapper";

// LEXUS squeezeAnimation (because animation names can change in the lexus css)
export const squeezeAnimation = keyframes`
    0% {
        transform: scaleX(1);
        transform-origin: right;
      }
    50% {
        transform: scaleX(0);
        transform-origin: right;
    }
    50.01% {
        transform: scaleX(0);
        transform-origin: left;
    }
    100% {
        transform: scaleX(1);
        transform-origin: left;
    }

`;

export type ButtonType = {
    size?: BreakPointValuesType;
    hasIcon?: boolean;
    hasIconRight?: boolean;
    cursor?: string;
    disabled?: boolean;
} & DisplayProps &
    WidthProps &
    MinWidthProps &
    SpaceProps &
    TypographyProps &
    ThemeTypedColorProps;

export const Button = styled.button<ButtonType>`
    display: inline-block;
    position: relative;
    padding: ${(props) => (props.size && props.size === "sm" ? "13px 13px 10px" : "23px 25px 20px")};
    font-family: ${(props) => props.theme.fontFamily.bold};
    font-size: 0.6875rem;
    letter-spacing: .1rem;
    line-height: 1.6;
    text-transform: uppercase;
    color: ${(props) => props.theme.textColor.button};
    border: 1px solid ${(props) => props.theme.color.grey5};
    border-radius: 0;
    cursor: pointer;
    ${width};
    ${space};
    ${typography};
    ${({ disabled, theme }) =>
        disabled &&
        css`
            background-color: ${theme.color.grey5} !important;
        `};
    @media ${getBreakpoint("up", "md")} {
        padding-${getDirection("right")}: ${(props) => (props.size && props.size === "sm" ? "40px" : "65px")};
        text-align: ${getDirection("left")};
        &::after {
            position: absolute;
            top: 50%;
            ${getDirection("right")}: 24px;
            width: 25px;
            height: 1px;
            content: "";
            background: ${(props) => props.theme.color.dark};
        }
        &:hover::after {
            animation: ${squeezeAnimation} 1s ease 0s infinite normal none;
        }
    }

    ${IconWrapper} {
        ${({ hasIconRight }) =>
            hasIconRight &&
            css`
            margin-${getDirection("left")}: 15px;
        `};
    }
`;

/* BUTTON TYPES */

export type PrimaryButtonType = { disabled?: boolean & TypographyProps & WidthProps };

export const Primary = styled(Button)<PrimaryButtonType>`
    color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.lightest)};
    background-color: ${({ disabled, theme }) => (disabled ? "transparent" : theme.color.primary)};
    border-color: ${({ disabled, theme }) => (disabled ? theme.color.grey2 : theme.color.primary)};
    &:visited {
        color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.lightest)};
    }
    &::after {
        background: ${(props) => props.theme.color.lightest};
    }

    ${({ disabled, theme }) =>
        disabled &&
        css`
            color: ${theme.color.grey5} !important;
            background-color: transparent !important;
            &::after {
                display: none;
            }
        `};
`;

export const Dark = styled(Button)`
    display: flex;
    color: ${({ disabled, theme }) => (disabled ? theme.color.grey5 : theme.color.lightest)};
    background-color: ${({ disabled, theme }) => (disabled ? "transparent" : theme.color.primary)};
    border-color: ${({ disabled, theme }) => (disabled ? theme.color.grey2 : theme.color.primary)};
    ${IconAnimatedToggleArrowStyle} {
        position: absolute;
        top: 50%;
        ${getDirection("right")}: 24px;
        transform: translateY(-50%);
        svg {
            top: 0;
        }
    }
    &::after {
        display: none;
    }
`;

export const OutlinePrimary = styled(Button)`
    ${({ theme }) =>
        theme &&
        css`
            color: ${theme.color.primary};
            background-color: ${theme.color.lightest};
            border: 1px solid ${theme.color.primary};
        `};
`;

export const Lightest = styled(Button)`
    ${({ theme }) =>
        theme &&
        css`
            background-color: ${theme.color.lightest};
            border: 1px solid ${theme.color.grey2};
        `};
`;

export const Negative = styled(Button)<ButtonType>`
    color: ${(props) => props.theme.textColor.button};
    background-color: ${(props) => props.theme.color.lightest};
`;

export const Light = styled(Button)`
    color: ${(props) => props.theme.textColor.button};
    background-color: ${(props) => props.theme.color.grey1};
`;

/* BUTTON STYLED AS A LINK */

type LinkType = {
    underline?: boolean;
    negative?: boolean;
    hasIconRight?: boolean;
    inlineIcon?: boolean;
    position?: string;
};

export const Link = styled(Button)<LinkType>`
    min-width: 0;
    padding: 0;
    padding-${getDirection("left")}: 26px;
    font-family: ${(props) => props.theme.fontFamily.regular};
    font-size: 17px; /* lexus uses px values */
    text-align: ${getDirection("left")};
    text-transform: none;
    color: ${({ theme, negative }) => (negative ? theme.color.lightest : theme.color.dark)};
    background-color: transparent;
    border: none;
    vertical-align: middle;
    ${position};
    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `};
    
    &::after {
        display: none;
    }
    &:disabled {
        text-decoration: none;
        background-color: transparent !important;
        opacity: 0.5;
    }
`;

/* REMOVE BUTTON BASED ON BUTTON.LINK */

export const Remove = styled(Link)`
    display: flex;
    justify-content: left;
    margin-top: 10px;
    padding: 13px;
    padding-${getDirection("left")}: 26px;
    color: ${(props) => props.theme.color.primary};
    &::after {
        display: none;
    }
    svg {
        display: none;
    }
`;

/* CTA ICON WRAPPER */

export const CtaIconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-${getDirection("right")}: 10px;
    color: ${({ theme }) => theme.color.lightest};
    background-color: ${({ theme }) => theme.color.primary};
    border-radius: 50%;
`;

export const EllipsisText = styled.span`
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

export default Button;
