import styled, { css } from "styled-components";
import { theme } from "../theme/lexusTheme";
import { EcoTagType } from "../../../../types/CommonTypes";

export const FuelTypeLabel = styled.span<{ type?: EcoTagType }>`
    font-family: ${theme.fonts.regular};
    font-size: 1.3rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    text-transform: uppercase;
    ${({ type }) =>
        type &&
        css`
            color: ${type === EcoTagType.FuelCell ? theme.colors.fuelCell : theme.colors.electricBlue};
        `};
`;

export default FuelTypeLabel;
