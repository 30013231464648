import React, { useMemo, useRef } from "react";
import * as Styles from "./styles/ResultImageSliderStyles";
import {
    Icon360,
    IconChevronLeft,
    IconChevronRight,
    IconVideo,
} from "../../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import ResultImage from "./ResultImage";
import useResultImageSlider from "../../../../hooks/car-results/useResultImageSlider";
import { UscAspectRatioType, UscObjectFitType } from "../../../../../shared-logic/types/UscCommonTypes";

type ResultImageSliderType = {
    images: { src: string; srcSet?: string }[];
    altTag: string;
    carUrl: string;
    urlTrack?: () => void;
    interactionTrack?: () => void;
    aspectRatio: UscAspectRatioType;
    objectFit: UscObjectFitType;
    show360Icon?: boolean;
    showVideoIcon?: boolean;
    invertedColour?: boolean;
};

/**
 * This component has some very specific behaviour to get optimal performance and UX with the least amount of downsides.
 *
 * - Only load slider images if the user has interacted with the slider.
 * - If possible, only render the slider when it is necessary.
 * - Resize/Reposition the slider if required (after a sidebar slide-in or window resize)
 * - Make sure images scale properly when the component changes size.
 *
 * For more info on how this happens see comments in component.
 */
const ResultImageSlider = (props: ResultImageSliderType): JSX.Element => {
    const { images, altTag, carUrl, urlTrack, interactionTrack, show360Icon, showVideoIcon } = props;
    const { invertedColour = false, aspectRatio, objectFit } = props;

    const viewportRef = useRef<HTMLDivElement>(null);
    const sliderRef = useRef<HTMLDivElement>(null);

    const {
        sliderProps,
        wrapperLinkProps,
        activeImage,
        showLeftArrow,
        showRightArrow,
        renderSlider,
        loadSliderImages,
        enableSliderCssTransitions,
        wrapEvent,
    } = useResultImageSlider(viewportRef, sliderRef, images, urlTrack, interactionTrack);

    const { startTouchDrag, startMouseDrag, sliderX, enableDrag, isScrolling, enableCssTransition } = sliderProps;
    const { leftArrowClickPage, rightArrowClickPage, onTransitionEnd } = sliderProps;

    // Stack of images to be rendered in the slider.
    // useMemo and React key are important here, this guarantees a smooth transition between active and inactive slider state.
    // We also add the add the carUrl here as otherwise the vehicle and it's image don't match when the filters are edited mid flight (see DR-76)
    const list = useMemo(
        () =>
            images.map(({ src, srcSet }, index) => (
                <ResultImage
                    src={src}
                    srcSet={srcSet}
                    key={index} // eslint-disable-line react/no-array-index-key
                    altTag={altTag}
                    startLoading={index === 0 || loadSliderImages}
                />
            )),
        [loadSliderImages, carUrl],
    );

    return (
        <a
            href={carUrl}
            onClick={wrapperLinkProps.onClick}
            onMouseEnter={wrapperLinkProps.onMouseEnter}
            onMouseLeave={wrapperLinkProps.onMouseLeave}
            onTouchStart={wrapperLinkProps.onTouchStart}
            data-testid="result-image-slider"
        >
            <Styles.Slider>
                <Styles.SliderLeft
                    show={showLeftArrow}
                    invertedColour={invertedColour}
                    onClick={wrapEvent(leftArrowClickPage, true)}
                >
                    <IconChevronLeft />
                </Styles.SliderLeft>
                <Styles.SliderRight
                    show={showRightArrow}
                    invertedColour={invertedColour}
                    onClick={wrapEvent(rightArrowClickPage, true)}
                >
                    <IconChevronRight />
                </Styles.SliderRight>

                <Styles.SliderViewport ref={viewportRef} objectFit={objectFit} hasAspectRatio43={aspectRatio === "4:3"}>
                    <Styles.Track
                        ref={sliderRef}
                        style={{
                            width: `${renderSlider ? images.length * 100 : 100}%`,
                        }}
                        position={renderSlider ? "absolute" : "relative"}
                        left={renderSlider ? sliderX : 0}
                        enableTransition={enableSliderCssTransitions && enableCssTransition}
                        onTransitionEnd={onTransitionEnd}
                        onTouchStart={enableDrag ? wrapEvent(startTouchDrag) : undefined}
                        onMouseDown={enableDrag ? wrapEvent(startMouseDrag) : undefined}
                        onClick={wrapEvent((event) => {
                            // Prevent the click from working when the slider is scrolling.
                            if (isScrolling.current) {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        })}
                        data-testid="result-image-slider-track"
                    >
                        {renderSlider ? list : list[activeImage]}
                    </Styles.Track>
                    <Styles.SliderBottom invertedColour={invertedColour}>
                        {show360Icon && (
                            <Styles.IconWrapper>
                                <Icon360 />
                            </Styles.IconWrapper>
                        )}
                        {showVideoIcon && (
                            <Styles.IconWrapper>
                                <IconVideo />
                            </Styles.IconWrapper>
                        )}
                    </Styles.SliderBottom>
                </Styles.SliderViewport>
            </Styles.Slider>
        </a>
    );
};

export default ResultImageSlider;
