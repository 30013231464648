import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";

const Label = styled.div<SpaceProps>`
    font-size: 1.6rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 2.4rem;
    ${({ theme }) => css`
        font-family: ${theme.fontFamily.regular};
        color: ${theme.color.dark};
    `};
    ${space};
`;

export const Light = styled(Label)`
    color: ${({ theme }) => theme.color.grey5};
`;

export const Bold = styled(Label)`
    font-family: ${({ theme }) => theme.fontFamily.bold};
`;

export const Highlight = styled(Label)`
    text-transform: uppercase;
    ${({ theme }) => css`
        font-family: ${theme.fontFamily.bold};
        color: ${theme.color.primary};
    `};
`;

export default Label;
