import React, { useState, KeyboardEvent, ChangeEvent, useEffect } from "react";
import {
    MinMaxType,
    SliderButtonType,
} from "../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { clampInputValue } from "../../../../../../shared-logic/features/filters/utils/filters";
import * as Styles from "./RangeSliderInput.styles";

type RangeSliderInputPropsType = {
    initialValues: MinMaxType; // Initial values for the slider inputs
    currentValues: MinMaxType; // Updates coming in from the slider used to update the inputs
    initialClampValues: MinMaxType; // Clamp values for the inputs
    setValueFn: (value: number, type: SliderButtonType) => void;
    labels: { from: string; to: string; prefix?: string; suffix?: string };
    name: string;
};

const RangeSliderInput = (props: RangeSliderInputPropsType): JSX.Element => {
    const { setValueFn, labels, name } = props;
    const { initialValues, currentValues, initialClampValues } = props;
    const { from: fromLabel, to: toLabel } = labels;

    const [minValue, setMinValue] = useState(initialValues.min);
    const [maxValue, setMaxValue] = useState(initialValues.max);

    const handleInputEnter = (evt: KeyboardEvent<HTMLInputElement>, type: SliderButtonType): void => {
        if (evt.key === "Enter") {
            const clampValues = {
                initial: initialClampValues,
                current: { min: minValue, max: maxValue },
            };

            const clampedValue = clampInputValue(Number(evt.currentTarget.value), clampValues, type, setValueFn);

            if (clampedValue) {
                setValueFn(clampedValue, type);
            }
        }
    };

    const handleInputChange = (evt: ChangeEvent<HTMLInputElement>, type: SliderButtonType): void => {
        const { value } = evt.target;

        // We're using type="text" for the inputs, so we need to do some checks to make sure we're
        // always dealing with numbers.
        const valueAsNumber = Number(value);
        if (!Number.isNaN(valueAsNumber)) {
            if (type === "min") {
                setMinValue(valueAsNumber);
            } else {
                setMaxValue(valueAsNumber);
            }
        } else if (type === "min") {
            setMinValue(initialValues.min);
        } else {
            setMaxValue(initialValues.max);
        }
    };

    /**
     * Update the input values when the slider values change.
     */
    useEffect(() => {
        setMinValue(currentValues.min);
        setMaxValue(currentValues.max);
    }, [currentValues]);

    return (
        <Styles.Wrapper>
            <Styles.Label
                htmlFor={`${name}-min`}
                aria-label={fromLabel}
                aria-valuemin={initialClampValues.min}
                aria-valuemax={initialClampValues.max}
            >
                {fromLabel}
                <Styles.InputWrapper>
                    {labels.prefix && <Styles.InputPrefix>{labels.prefix}</Styles.InputPrefix>}
                    <Styles.Input
                        // Read https://technology.blog.gov.uk/2020/02/24/why-the-gov-uk-design-system-team-changed-the-input-type-for-numbers/ for justification
                        // on using type="text" here instead of type="number"
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        id={`${name}-min`}
                        value={minValue}
                        onChange={(evt): void => handleInputChange(evt, "min")}
                        onKeyDown={(evt): void => handleInputEnter(evt, "min")}
                        aria-label={fromLabel}
                        aria-valuemin={initialClampValues.min}
                        aria-valuemax={initialClampValues.max}
                    />
                    {labels.suffix && <Styles.InputSuffix>{labels.suffix}</Styles.InputSuffix>}
                </Styles.InputWrapper>
            </Styles.Label>

            <Styles.Label
                htmlFor={`${name}-max`}
                aria-label={toLabel}
                aria-valuemin={initialClampValues.min}
                aria-valuemax={initialClampValues.max}
            >
                {toLabel}
                <Styles.InputWrapper>
                    {labels.prefix && <Styles.InputPrefix>{labels.prefix}</Styles.InputPrefix>}
                    <Styles.Input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        id={`${name}-max`}
                        value={maxValue}
                        onChange={(evt): void => handleInputChange(evt, "max")}
                        onKeyDown={(evt): void => handleInputEnter(evt, "max")}
                        onBlur={(evt): void => handleInputChange(evt, "max")}
                        aria-label={toLabel}
                        aria-valuemin={initialClampValues.min}
                        aria-valuemax={initialClampValues.max}
                    />
                    {labels.suffix && <Styles.InputSuffix>{labels.suffix}</Styles.InputSuffix>}
                </Styles.InputWrapper>
            </Styles.Label>
        </Styles.Wrapper>
    );
};

export default RangeSliderInput;
