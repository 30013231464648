import styled from "styled-components";
import * as InputGroupStyle from "../../../../../../../common-deprecated/features/filters/styles/InputGroup";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import Preloader from "../../../../../../../common-deprecated/styles/v2/lexus/custom/Preloader";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Wrapper = styled.div`
    position: relative;
    min-height: 453px;
`;

export const FormGroup = styled.div`
    position: relative;
    width: 100%;
`;

export const ResultsWrapper = styled.div`
    position: relative;
    width: 100%;
    max-height: 310px;
    margin-top: -2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0px 15px 80px 0px rgba(0, 0, 0, 0.04), 0px 4.522px 24.118px 0px rgba(0, 0, 0, 0.05),
        0px 1.878px 10.017px 0px rgba(0, 0, 0, 0.03), 0px 0.679px 3.623px 0px rgba(0, 0, 0, 0.02);
    overflow: auto;
`;

export const Result = styled.div`
    width: 100%;
    border-top: 1px solid ${theme.colors.platinumLight};
`;

export const ErrorMessage = styled.div`
    width: 100%;
    height: 44px;
    padding-top: 9px;
    text-align: center;
    color: ${theme.colors.midnight};
    background: ${theme.colors.white};
    border-top: 1px solid ${theme.colors.platinumLight};
`;

export const MapsWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const InputAction = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    padding-top: 2px;
    background: none;
    border: none;
    .icon::before {
        font-size: 1.6rem;
    }
`;

export const LocationButton = styled.button<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: none;
    border: none;
    border-left: 1px solid ${theme.colors.platinumLight};
`;

export const InputGroup = styled(InputGroupStyle.Wrapper)`
    input {
        flex: 1 1 auto;
        width: 1%;
        padding: 10px 12px;
        font-size: 1.6rem;
        line-height: 1.5;
    }
`;

export const InputAppend = styled(InputGroupStyle.InputAppend)`
    position: static;
    top: auto;
    ${getDirection("right")}: auto;
    width: auto;
    height: auto;

    svg {
        background-color: transparent;
    }

    ${Preloader} {
        left: auto;
        right: 44px;
        width: 44px;
        height: 44px;
    }
`;

export const RangeFilterIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 12px;
    border-left: 1px solid ${theme.colors.platinumLight};
`;

export const RangeFilterIcon = styled.span``;

export const ResultsTitleWrapper = styled.div`
    width: 100%;
    padding: ${theme.space[1]}px ${theme.space[2]}px;
    background-color: ${theme.colors.mist};
    border-top: 1px solid ${theme.colors.platinumLight};
    border-bottom: 1px solid ${theme.colors.platinumLight};

    & + ${Result} {
        border-top: 0;
    }
`;

export const ResultsTitle = styled.span`
    font-family: ${theme.fonts.book};
    font-size: 13px;
    font-weight: 600;
    line-height: 1.5;
    text-transform: uppercase;
    color: ${theme.colors.platinumDark};
`;
