import styled, { css } from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${theme.space[3]}px;
`;

export const InputWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    margin-top: auto;
`;

export const Label = styled.label`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: ${theme.space[1]}px;
    font-family: ${theme.fonts.bold};
    font-size: 1.3rem;
    line-height: 2rem;
`;

export const Input = styled.input`
    width: 100%;
    height: 48px;
    padding: ${theme.space[3] / 2}px;
    font-family: ${theme.fonts.book};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.azure};
    border: 1px solid ${theme.colors.platinumLight};
    border-radius: none;
`;

export const preSuffixStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: ${theme.space[3] / 2}px;
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${theme.colors.platinumDark};
    background-color: ${theme.colors.mist};
    border: 1px solid ${theme.colors.platinumLight};
`;

export const InputSuffix = styled.span`
    ${preSuffixStyle};
    border-left: none;
`;

export const InputPrefix = styled.span`
    ${preSuffixStyle};
    border-right: none;
`;
