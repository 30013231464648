import styled, { css } from "styled-components";
import { maxWidth, MaxWidthProps, minWidth, MinWidthProps } from "styled-system";

/**
 * Ellipsis is a utility component that will proper truncate a string to one line
 * @example
 * export const Title = styled(Type.H2)`
 *   font-family: ${theme.fonts.regular};
 *   font-size: 2rem;
 *   ${ellipsisStyle};
 * `;
 */
export const EllipsisStyle = css<MaxWidthProps & MinWidthProps>`
    display: inline-block;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    ${maxWidth};
    ${minWidth};
`;

export const Ellipsis = styled.span<MaxWidthProps & MinWidthProps>`
    ${EllipsisStyle};
`;
