import React from "react";
import * as Styles from "./df-car-result/styles/UsedCarResultStyles";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import { IconWrapper } from "../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import { Ellipsis } from "../../../../../common-deprecated/styles/v2/lexus/utilities/Ellipsis";
import { IconCheckmark, IconCompare } from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";

type CompareButtonType = {
    onClick: () => void;
    isActive?: boolean;
};

const CompareButton = ({ onClick, isActive }: CompareButtonType): JSX.Element => {
    const compareLabel = useCarFilterLabel("carFilterResultCompare");

    return (
        <Styles.Button
            onClick={(evt) => {
                evt.stopPropagation();
                onClick();
            }}
            isActive={isActive}
        >
            <IconWrapper>{isActive ? <IconCheckmark /> : <IconCompare />}</IconWrapper>
            <Ellipsis maxWidth="100%">{compareLabel}</Ellipsis>
        </Styles.Button>
    );
};

export default CompareButton;
