import React, { Fragment, useMemo } from "react";
import { shallowEqual } from "react-redux";
import { useCarFilterSelector } from "../../../redux/store";
import UsedCarResult from "./df-car-result/UsedCarResult";
import UsedCarResultPlaceholder from "./df-car-result/UsedCarResultPlaceholder";
import * as Preloader from "../../../../../common-deprecated/styles/v2/lexus/custom/Preloader";
import * as Styles from "./styles/CarResultsContainerStyles";
import { UsedCarResultType } from "../../../../shared-logic/types/UscCommonTypes";
import ContentBlock from "./ContentBlock";
import { getContentBlockConfig } from "../../../utils/helpers";
import NoResults from "./NoResults";
import useCarResultsContainer from "../../../hooks/car-results/useCarResultsContainer";
import PaginationControls from "./PaginationControls";
import OutOfBounds from "./OutOfBounds";
import ActiveFilterSliderContainer from "../active-filters/slider/ActiveFilterSliderContainer";
import FilterList from "../filters/FilterList";
import FilterSavedCars from "../filters/FilterSavedCars";

const CarResultsContainer = (): JSX.Element => {
    const initialized = useCarFilterSelector((state) => state.carResults.initialized);
    const loadingMore = useCarFilterSelector((state) => state.carResults.loadingMore);
    const contentBlockConfig = useCarFilterSelector(({ carFilterSettings, carFilters }) =>
        getContentBlockConfig(carFilterSettings, carFilters.currentFilter),
    );
    const { page, totalPages } = useCarFilterSelector((state) => state.carFilters.pagination, shallowEqual);
    const { renderedResults, resultKey, contentBlockIndex } = useCarResultsContainer();
    const showMoreFilters = useCarFilterSelector((state) => state.carFilters.showMoreFilters);
    const isWithinPageRange = page <= totalPages || totalPages === 0;

    // Create an array of results/placeholders to be rendered.
    const resultComponents = renderedResults.map(({ placeholder, result, loading }, index) => {
        return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
                {placeholder && <UsedCarResultPlaceholder />}
                {!placeholder && result && <UsedCarResult result={result as UsedCarResultType} />}
            </Fragment>
        );
    });

    // Add the value proposition in between the results if required (see init hook)
    if (
        contentBlockConfig &&
        contentBlockIndex !== null &&
        renderedResults.length > 0 &&
        renderedResults.length < 12 &&
        page === 1 &&
        !loadingMore
    ) {
        // Make sure that the index is added at the correct place even when there are less than 5 results.
        // Example: vpIndex is 4, there are only 2 results, make sure the VP is added at index 2 instead of 4.
        const actualVpIndex = Math.min(contentBlockIndex, renderedResults.length - 1);
        resultComponents.splice(
            actualVpIndex,
            0,
            <Fragment key="valueProposition">
                <ContentBlock contentBlockConfig={contentBlockConfig} />
            </Fragment>,
        );
    }

    return useMemo(
        () => (
            <>
                {!initialized && loadingMore && (
                    <Preloader.Wrapper width={1} position="relative" data-testid="preloader">
                        <Preloader.default />
                    </Preloader.Wrapper>
                )}
                {initialized && (
                    <Styles.Grid showMoreFilters={showMoreFilters}>
                        <Styles.SidebarWrapper
                            // Calculate the number of rows that are available in the grid
                            // so we can calculate the correct height of the sidebar in CSS
                            rowsLG={Math.ceil((renderedResults.length + (contentBlockIndex ? 1 : 0)) / 2)}
                            rowsXXL={Math.ceil((renderedResults.length + (contentBlockIndex ? 1 : 0)) / 3)}
                            showMoreFilters={showMoreFilters}
                        >
                            <div>
                                <FilterSavedCars />
                                <ActiveFilterSliderContainer />
                                <FilterList />
                            </div>
                        </Styles.SidebarWrapper>
                        {resultComponents}
                        {!renderedResults.length && initialized && isWithinPageRange && (
                            <NoResults showMoreFilters={showMoreFilters} />
                        )}
                        {initialized && !isWithinPageRange && <OutOfBounds showMoreFilters={showMoreFilters} />}
                    </Styles.Grid>
                )}
                {initialized && !!renderedResults.length && <PaginationControls />}
            </>
        ),
        [initialized, loadingMore, resultKey, resultComponents, showMoreFilters],
    );
};

export default CarResultsContainer;
