import styled, { css } from "styled-components";
import { parseHexToRGB } from "../../../../utils";
import { theme } from "../theme/lexusTheme";
import { ctaStyle } from "../globals/Type";

export const ToggleItem = styled.button<{ isActive?: boolean }>`
    position: relative;
    width: 143px;
    min-height: 44px;
    max-height: 64px;
    margin: 0;
    padding: 12px;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: uppercase;
    color: ${({ isActive }) => (isActive ? theme.colors.azure : theme.colors.white)};
    background-color: transparent;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    overflow: hidden;
    pointer-events: auto;
    z-index: 10;
    ${ctaStyle};
`;

export const Wrapper = styled.div<{
    isMonthlyActive?: boolean;
    isActive?: boolean;
    fullWidth?: boolean;
    darkVariant?: boolean;
}>`
    display: flex;
    position: relative;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
    max-width: ${({ fullWidth }) => (fullWidth ? "none" : "295px")};
    padding: 5px;
    background-color: ${({ darkVariant }) =>
        darkVariant ? "rgba(255, 255, 255, .6)" : `rgba(${parseHexToRGB(theme.colors.azure).rgb}, 0.6)`};
    border-radius: 3px;

    &::before {
        display: block;
        position: absolute;
        top: 5px;
        left: ${({ isMonthlyActive, isActive, fullWidth }) =>
            isMonthlyActive || isActive ? (fullWidth ? "calc(50% - 5px)" : "calc(100% - 143px - 5px)") : "5px"};
        width: ${({ fullWidth }) => (fullWidth ? "50%" : "143px")};
        height: calc(100% - 10px);
        min-height: 44px;
        max-height: 64px;
        content: "";
        background-color: ${theme.colors.white};
        border-radius: 1px;
        transition: left 0.3s ease-out;
        z-index: 5;
    }

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            ${ToggleItem} {
                width: 50%;
            }
        `}
`;

export const LineClamp = styled.span`
    /* stylelint-disable */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* stylelint-enable */
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
`;
