import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { srOnlyStyle } from "../../../../../../../common-deprecated/styles/v2/lexus/utilities/ScreenreaderOnly";

export const Wrapper = styled.div`
    display: block;
    margin: ${theme.space[3] / 2}px 0;
    border-radius: 4px;
    box-shadow: 0px 15px 80px 0px rgba(0, 0, 0, 0.04), 0px 4.522px 24.118px 0px rgba(0, 0, 0, 0.05),
        0px 1.878px 10.017px 0px rgba(0, 0, 0, 0.03), 0px 0.679px 3.623px 0px rgba(0, 0, 0, 0.02);
`;

export const Label = styled.label`
    ${srOnlyStyle};
`;

export const ComboWrapper = styled.div`
    background-color: ${theme.colors.white};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
`;

export const ComboMenu = styled.ul`
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 10px ${theme.space[2]}px;

        &:is(:hover, :focus) {
            background-color: ${theme.colors.silver};
        }

        &:not(:nth-last-child(1)) {
            border-bottom: 1px solid ${theme.colors.platinumLight};
        }

        span {
            font-family: ${theme.fonts.book};
            font-size: 1.6rem;
            line-height: 2.4rem;

            &:first-child {
                text-align: start;
                color: ${theme.colors.midnight};
            }
            &:last-child {
                text-align: end;
                color: ${theme.colors.platinumDark};
            }
        }
    }
`;

// Overwriting clientlib styles here, in the ComboItem component
// we'll only ever render text, so we can safely overwrite the
// default styles.
export const ComboItem = styled.li<{ bottomBorder?: boolean }>`
    * {
        margin: 0;
        padding: 0;
    }

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 10px ${theme.space[2]}px;

    &:is(:hover, :focus) {
        background-color: ${theme.colors.silver};
    }

    ${({ bottomBorder }) => bottomBorder && `border-bottom: 1px solid ${theme.colors.platinumLight};`}
`;

export const ComboItemResultsText = styled.p`
    font-family: ${theme.fonts.bold};
    color: ${theme.colors.platinumDark};
`;

export const ComboInputText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: ${theme.space[3] / 2}px;
    padding: 10px ${theme.space[2]}px;

    div {
        font-family: ${theme.fonts.book};
        font-size: 1.6rem;
        line-height: 2.4rem;

        &:first-child {
            text-align: start;
            color: ${theme.colors.midnight};
        }
        &:last-child {
            text-align: end;
            color: ${theme.colors.platinumDark};
        }
    }
`;

export const ComboInputIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${theme.space[3] / 2}px;
    border-left: 1px solid ${theme.colors.platinumLight};
`;

export const ComboInput = styled.div`
    display: flex;
    flex: 1 0 0;

    &[aria-expanded="true"] {
        border-bottom: 1px solid ${theme.colors.platinumLight};

        ${ComboInputIcon} svg, .dxp-icon {
            transform: rotate(180deg);
        }
    }

    &[aria-expanded="true"] + ${ComboMenu} {
        display: block;
    }
`;

export const FieldRadius = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    p:nth-of-type(2) {
        text-align: end;
    }
`;

// Collapse
export const CollapseContent = styled.div`
    display: none;
    padding: ${theme.space[3] / 2}px ${theme.space[2]}px;
    font-family: ${theme.fonts.book};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.midnight};
    background-color: ${theme.colors.mist};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
`;

export const CollapseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: ${theme.space[3] / 2}px;
    padding: 6px ${theme.space[2]}px;
    font-family: ${theme.fonts.book};
    font-size: 1.3rem;
    line-height: 2rem;
    color: ${theme.colors.midnight};
    background-color: ${theme.colors.mist};
    border-top: 1px solid ${theme.colors.platinumLight};

    &[aria-expanded="true"] {
        border-bottom: 1px solid ${theme.colors.platinumLight};

        svg,
        .dxp-icon {
            transform: rotate(180deg);
        }
    }

    &[aria-expanded="true"] + ${CollapseContent} {
        display: block;
    }
`;
