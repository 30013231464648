import React from "react";
import * as Modal from "../../../../styles/v2/lexus/globals/Modal";
import Icon from "../../../../styles/v2/lexus/globals/Icon";
import { IconWrapper } from "../../../../styles/v2/lexus/globals/IconWrapper";
import SrOnly from "../../../../styles/v2/lexus/utilities/ScreenreaderOnly";
import ModalBaseView from "./ModalBaseView";
import { ModalContentType } from "../../../../utils/modalConstants";
import { ModalViewType } from "../../../../types/CommonTypes";
import { CommonLabel } from "../../../../utils/commonLabels";

// Basic modal that shows some plaintext or html content
const ContentModal = (props: ModalViewType<ModalContentType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { content, useHTML = false, onClose, title, footer } = modalSettings;

    // combine both manually set close handler and common modal close handler
    const closeHandler = (): void => {
        close();
        if (onClose) {
            onClose();
        }
    };

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={closeHandler} show={show} size="md">
            <Modal.Header>
                {title && <Modal.Title>{title}</Modal.Title>}
                <Modal.Button type="button" onClick={closeHandler}>
                    <SrOnly>
                        <CommonLabel label="close" />
                    </SrOnly>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                {useHTML && typeof content === "string" ? (
                    <span dangerouslySetInnerHTML={{ __html: content }} />
                ) : (
                    content
                )}
                {footer && (
                    <>
                        <br />
                        {footer}
                    </>
                )}
            </Modal.Body>
        </ModalBaseView>
    );
};

export default ContentModal;
