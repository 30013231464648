import React from "react";
import { useCarFilterSelector } from "../../../redux/store";
import { getPageDisclaimerLabel } from "../../../utils/helpers";
import { useCarFilterLabel, useCarFilterOptionalLabel } from "../../../utils/constants/labels";
import * as Styles from "../../../../../common-deprecated/styles/v2/lexus/custom/PageDisclaimer";
import Container from "../../../../../common-deprecated/styles/v2/lexus/globals/Container";

const PageDisclaimers = (): JSX.Element | null => {
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);

    const disclaimerTitleLabel = useCarFilterLabel("carFilterPageDisclaimerTitle");
    const disclaimerLabel = useCarFilterOptionalLabel(getPageDisclaimerLabel(currentFilter));

    if (!disclaimerLabel) return null;

    return (
        <Container>
            <Styles.Wrapper>
                <Styles.Title>{disclaimerTitleLabel}</Styles.Title>
                {disclaimerLabel && <Styles.Text>{disclaimerLabel}</Styles.Text>}
            </Styles.Wrapper>
        </Container>
    );
};

export default PageDisclaimers;
