import styled from "styled-components";
import * as FilterDropdownStyles from "../../../styles/FilterDropdownStyles";
import * as Flex from "../../../../../../../../common-deprecated/styles/v2/lexus/globals/Flex";
import { DropdownChildWrapper } from "../../../../../../../../common-deprecated/components/dropdown/DropdownContentStyles";
import { h5Style } from "../../../../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import { theme } from "../../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import LabelStyle from "../../../../../../../../common-deprecated/features/filters/styles/Label";
import { Wrapper as BrandModelListWrapper } from "./BrandModelListStyles";

export const Wrapper = styled.div`
    ${FilterDropdownStyles.Header} {
        font-family: ${(props) => props.theme.fontFamily.regular};
    }

    /* Add spacing to latest item in dropdown list */
    ${Flex.Row} {
        &:last-child {
            padding-bottom: 20px;
        }
    }

    ${BrandModelListWrapper} {
        padding-top: 0;
    }

    ${DropdownChildWrapper} {
        padding-bottom: 0;
    }
`;

export const Label = styled.h3`
    margin-top: ${theme.space[3]}px;
    margin-bottom: 0;
    ${h5Style};
`;

export const NestedTitle = styled(LabelStyle)`
    margin-bottom: ${theme.space[3]}px;
    font-family: ${theme.fonts.bold};
`;
