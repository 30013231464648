import styled, { css } from "styled-components";
import { space, SpaceProps, color, ColorProps, display, DisplayProps } from "styled-system";
import { getBreakpoint } from "../../../../themes/common";
import { theme } from "../theme/lexusTheme";

/**
 * Utility class for re-using typography styles in other components.
 */

export type TextStyleType = {
    isSmall?: boolean;
    isPdf?: boolean;
};

export const h1Style = css`
    font-family: ${theme.fonts.regular};
    font-size: 3.2rem;
    letter-spacing: 0.2rem;
    line-height: 4rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 4.8rem;
        line-height: 6rem;
    }
    @media ${getBreakpoint("up", "xxl")} {
        font-size: 6rem;
        line-height: 7.2rem;
    }
`;

export const h2Style = css`
    font-family: ${theme.fonts.regular};
    font-size: 2.8rem;
    letter-spacing: 0.2rem;
    line-height: 3.6rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 4rem;
        line-height: 5rem;
    }
`;

export const h3Style = css`
    font-family: ${theme.fonts.regular};
    font-size: 2.4rem;
    letter-spacing: 0.2rem;
    line-height: 3.2rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 3.2rem;
        line-height: 4rem;
    }
    @media ${getBreakpoint("up", "xxl")} {
        font-size: 4rem;
        line-height: 5rem;
    }
`;

export const h4Style = css`
    font-family: ${theme.fonts.regular};
    font-size: 2rem;
    letter-spacing: 0.15rem;
    line-height: 2.8rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 2.8rem;
        line-height: 3rem;
    }
`;

export const h5Style = css`
    font-family: ${theme.fonts.regular};
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
    line-height: 2.2rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 1.6rem;
    }
    @media ${getBreakpoint("up", "xxl")} {
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
`;

export const h6Style = css`
    font-family: ${theme.fonts.regular};
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    line-height: 2rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 1.4rem;
    }
    @media ${getBreakpoint("up", "xxl")} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;

export const quoteStyle = css`
    font-family: ${theme.fonts.book};
    font-size: 2.4rem;
    letter-spacing: 0.1rem;
    line-height: 3.6rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 2.6rem;
        line-height: 3.8rem;
    }
    @media ${getBreakpoint("up", "xxl")} {
        font-size: 2.8rem;
        line-height: 4rem;
    }
`;

export const bodyStyle = css<TextStyleType>`
    font-family: ${theme.fonts.book};
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    line-height: 3rem;

    ${(props) =>
        props.isSmall &&
        css`
            font-size: 1.2rem;
            line-height: 1.8rem;
        `};
`;

export const listItemStyle = css`
    font-family: ${theme.fonts.book};
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
`;

export const ctaStyle = css`
    font-family: ${theme.fonts.bold};
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
`;

export const captionStyle = css`
    font-family: ${theme.fonts.book};
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    text-transform: uppercase;
`;

export const legalStyle = css`
    font-family: ${theme.fonts.book};
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    line-height: 1.8rem;
    @media ${getBreakpoint("up", "lg")} {
        font-size: 1.4rem;
        line-height: 2rem;
    }
`;

/**]
 * Typography styles
 */

export const HEADING_TYPES = [
    "alpha",
    "base",
    "beta",
    "delta",
    "epsilon",
    "femto",
    "gamma",
    "micro",
    "nano",
    "sub-micro",
    "zeta",
];

export const STYLE_TYPES = ["text", "headline"];
export const TEXT_TRANSFORMS = ["none", "uppercase", "lowercase", "capitalize"];

type HeadingType = {
    headingType?: (typeof HEADING_TYPES)[number];
    styleType?: (typeof STYLE_TYPES)[number];
    textTransform?: (typeof TEXT_TRANSFORMS)[number];
};

const textTransformStyle = css<HeadingType>`
    ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
`;

export const H1 = styled.h1.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `l-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const H2 = styled.h2.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `l-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const H3 = styled.h3.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `l-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const H4 = styled.h4.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `l-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const H5 = styled.h5.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `l-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const H6 = styled.h6.attrs<HeadingType>((props) => ({
    className: `${props.headingType ? `l-${props.headingType}-text` : ""}`,
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const Strong = styled.strong.attrs((props) => ({
    className: "l-base-headline",
}))<DisplayProps & SpaceProps & HeadingType>`
    ${display};
    ${space};
    ${textTransformStyle};
`;

export const Text = styled.span.attrs<HeadingType>((props) => ({
    className: `l-${props.headingType ? props.headingType : "base"}-${props.styleType ? props.styleType : "text"}`,
}))<HeadingType & SpaceProps & ColorProps>`
    ${space};
    ${color};
`;

export const MicroText = styled.span.attrs((props) => ({
    className: "l-micro-text",
}))<SpaceProps & ColorProps>`
    ${space};
    ${color};
`;

export const SubMicroText = styled.span.attrs<HeadingType>((props) => ({
    className: "l-sub-micro-text",
}))<HeadingType & SpaceProps>`
    ${space};
    ${textTransformStyle};
`;

export const NanoText = styled.span.attrs((props) => ({
    className: "l-nano-text",
}))<SpaceProps>`
    ${space};
`;

export const NanoHeadline = styled.span.attrs((props) => ({
    className: "l-nano-headline",
}))<SpaceProps>`
    ${space};
`;

export const SmallText = styled.span.attrs((props) => ({
    className: "l-nano-text",
}))<SpaceProps & ColorProps>`
    ${space};
    ${color};
`;

export const SmallHeading = styled.span.attrs((props) => ({
    className: "l-nano-headline",
}))<SpaceProps>`
    ${space};
`;

export const Body = styled.span`
    ${bodyStyle};
`;
