import React from "react";
import { shallowEqual } from "react-redux";
import * as Styles from "./TopFiltersStyles";
import TopFilterSectionWrapper from "./TopFilterSectionWrapper";
import { useCarFilterSelector } from "../../../redux/store";
import { mainFilterSelector } from "../../../utils/filters";
import { getAemTrackingDefaults } from "../../../utils/tracking";
import { trackEvent } from "../../../../../common-deprecated/utils/tracking";

const TopFilters = (): JSX.Element => {
    const mainFilters = useCarFilterSelector(mainFilterSelector, shallowEqual);
    const carFilters = useCarFilterSelector((state) => state.carFilters);

    return (
        <Styles.Filters filterItems={mainFilters.length}>
            {mainFilters.map((filterId) => (
                <TopFilterSectionWrapper
                    key={filterId}
                    filterId={filterId}
                    showFilter={() => {
                        trackEvent({
                            ...getAemTrackingDefaults(carFilters.currentFilter),
                            action: "open-filter-standard",
                            label: filterId,
                        });
                    }}
                />
            ))}
        </Styles.Filters>
    );
};

export default TopFilters;
