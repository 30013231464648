import React, { useEffect, useRef, useState } from "react";
import DropdownContainer from "../../../../../common-deprecated/components/dropdown/DropdownContainer";
import Icon from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import * as Styles from "./styles/FilterDropdownStyles";
import useFilterDropDown from "../../../../../shared-logic/features/filters/hooks/useFilterDropDown";
import { FILTER_BAR_HEIGHT } from "../active-filters/top-bar/styles/StaticFilterBarStyles";
import { useWindowDimensions } from "../../../../../common-deprecated/hooks";

type FilterDropdownType = {
    onClick: () => void;
    show: boolean;
    title: string;
    children: React.ReactNode;
};

const FilterDropdown = (props: FilterDropdownType): JSX.Element => {
    const { show, onClick, title, children } = props;
    const { propagatedShow, renderDropdownContainer } = useFilterDropDown(show);
    const [maxHeightTopOffset, setMaxHeightTopOffset] = useState(0);
    const headerRef = useRef<HTMLDivElement>(null);
    const windowWidth = useWindowDimensions().width;

    useEffect(() => {
        if (headerRef.current) {
            const { height } = headerRef.current.getBoundingClientRect();
            setMaxHeightTopOffset(height + FILTER_BAR_HEIGHT); // header height + static filter bar height
        }
    }, [maxHeightTopOffset, windowWidth]);

    return (
        <Styles.Wrapper show={propagatedShow} role="listitem">
            <Styles.Header ref={headerRef} onClick={onClick} show={propagatedShow}>
                {title}
                <Styles.IconWrapper isDown={propagatedShow}>
                    <Icon variant="chevronDown" />
                </Styles.IconWrapper>
            </Styles.Header>
            {renderDropdownContainer && (
                <DropdownContainer show={propagatedShow} maxHeightTopOffset={maxHeightTopOffset} scrollIntoView>
                    <Styles.DropdownContent>{children}</Styles.DropdownContent>
                </DropdownContainer>
            )}
        </Styles.Wrapper>
    );
};

export default FilterDropdown;
