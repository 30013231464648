import { aemLexusCommonModalMap } from "../../../../../common-deprecated/components/modals/aem/lexus/modals";
import VehicleForSaleIdModal from "../../../../shared-components/toyota/modals/vehicle-for-sale-id-modal/VehicleForSaleIdModal";
import {
    MODAL_USC_INJECTION,
    MODAL_VEHICLE_FOR_SALE_ID,
    SharedUscModalIdsType,
} from "../../../../shared-logic/utils/modalConstants";
import { ModalMapType } from "../../../../../common-deprecated/types/CommonTypes";
import {
    CarFilterModalIdsType,
    MODAL_FILTER_CAR_FILTERS,
    MODAL_FILTER_CONFIG_HELPER,
    MODAL_FILTER_SORT_FILTERS,
} from "../../../utils/modalConstants";
import ConfigHelperModal from "../../toyota/modals/ConfigHelperModal";
import CarFiltersModal from "./CarFiltersModal";
import SortFiltersModal from "./SortFiltersModal";
import UscInjectionModal from "./UscInjectionModal";

export const aemLexusCarFilterModalMap: ModalMapType<CarFilterModalIdsType & SharedUscModalIdsType> = {
    ...aemLexusCommonModalMap,
    [MODAL_FILTER_CONFIG_HELPER]: ConfigHelperModal,
    [MODAL_VEHICLE_FOR_SALE_ID]: VehicleForSaleIdModal,
    [MODAL_FILTER_CAR_FILTERS]: CarFiltersModal,
    [MODAL_FILTER_SORT_FILTERS]: SortFiltersModal,
    [MODAL_USC_INJECTION]: UscInjectionModal,
};
