import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { CarFilterDispatchType } from "../../../redux/store";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import * as Styles from "./styles/OutOfBoundsStyles";
import { setPage } from "../../../redux/actions/CarFiltersActions";
import ButtonTertiary from "../../../../../common-deprecated/components/aem/lexus/button/ButtonTertiary";

type OutOfBoundsPropsType = {
    showMoreFilters: boolean;
};

/**
 * Component presented to the user when they try to navigate to a page that is out of bounds.
 * Out of bounds implies that the current page is larger than the total number of pages.
 */
const OutOfBounds: FC<OutOfBoundsPropsType> = ({ showMoreFilters }) => {
    const dispatch = useDispatch<CarFilterDispatchType>();

    const [titleLabel, descriptionLabel, buttonLabel] = useCarFilterLabel([
        "carFilterPaginationOutOfBoundsTitle",
        "carFilterPaginationOutOfBoundsDescription",
        "carFilterPaginationOutOfBoundsButton",
    ]);

    return (
        <Styles.Wrapper showMoreFilters={showMoreFilters}>
            <Styles.OutOfBoundsTextWrapper>
                <Styles.OutOfBoundsMessage>{titleLabel}</Styles.OutOfBoundsMessage>
                <Styles.OutOfBoundsDescription>{descriptionLabel}</Styles.OutOfBoundsDescription>
            </Styles.OutOfBoundsTextWrapper>

            <ButtonTertiary onClick={() => dispatch(setPage(1))}>{buttonLabel}</ButtonTertiary>
        </Styles.Wrapper>
    );
};

export default OutOfBounds;
