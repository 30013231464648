import styled from "styled-components";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Wrapper = styled.div``;

export const Button = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 44px;
    padding: 10px 20px 10px 10px;
    background: ${theme.colors.white};
    border: none;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-${getDirection("right")}: ${theme.space[3] / 2}px;
    color: ${theme.colors.midnight};
`;

export const DealerInfo = styled.div`
    width: 100%;
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const Address = styled.span`
    color: ${theme.colors.platinumDark};
`;
