import styled, { css } from "styled-components";
import { width, WidthProps } from "styled-system";
import { theme as lexusTheme } from "../theme/lexusTheme";
import { getDirection } from "../../../../themes/common";

// TODO Should probably align this Table.ts with the one in /common?
const TableCellStyles = css`
    padding: 10px 15px 12px;
    font-weight: normal;
    line-height: 1.1;
    text-align: ${getDirection("left")};
    border: 1px solid ${lexusTheme.colors.platinumLight};
    vertical-align: top;
`;

export const Base = styled.table<{ bordered?: boolean }>`
    width: 100%;
    max-width: 100%;
    margin-bottom: ${lexusTheme.space[3]}px;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    box-sizing: border-box;
    ${({ bordered }) =>
        bordered &&
        css`
            border: 1px solid ${lexusTheme.colors.platinumLight};
        `};
`;

export const TBody = styled.tbody``;

export const TR = styled.tr``;

export const TH = styled.th<WidthProps>`
    ${TableCellStyles} ${width};
`;

export const TD = styled.td<{ weight?: any } & WidthProps>`
    ${TableCellStyles}
    ${width}
    ${({ weight, theme }) =>
        weight === "bold" &&
        css`
            font-family: ${theme.fontFamily.bold};
        `}
`;
