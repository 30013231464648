import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { h5Style } from "../../../../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import * as FilterBackButton from "../../../../common/styles/FilterBackButtonStyles";

export const Wrapper = styled.div``;

export const Brand = styled.div`
    padding: 12px 12px 4px 0;
    border-bottom: 1px solid ${(props) => props.theme.color.grey2};

    ${FilterBackButton.Button} {
        padding-left: 0;
        padding-right: 0;
    }
`;

export const Label = styled.div<SpaceProps>`
    text-transform: uppercase;
    ${h5Style};
    ${space};
`;
