import styled from "styled-components";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import { breakpoints, maxBreakpoints } from "../../../../../shared-logic/themes/lexusTheme";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Container = styled.div`
    width: 100%;

    @media screen and (min-width: ${breakpoints.xl}) {
        max-width: 1440px;
    }

    @media screen and (min-width: ${breakpoints.max}) {
        max-width: 1600px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
`;

export const SidebarWrapper = styled.div<{ showMoreFilters?: boolean; rowsLG?: number; rowsXXL?: number }>`
    /* Default fallback from rows should be always 2 */
    --rowsLG: ${({ rowsLG }) => (rowsLG && rowsLG > 1 ? rowsLG : 2)};
    --rowsXXL: ${({ rowsXXL }) => (rowsXXL && rowsXXL > 1 ? rowsXXL : 2)};

    display: ${({ showMoreFilters }) => (showMoreFilters ? "block" : "none")};
    position: fixed;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    padding: 0 ${theme.space[2]}px;
    background-color: ${theme.colors.white};
    overflow-x: hidden;
    overflow-y: auto;
    z-index: ${theme.zIndex.popover + 1};

    @media screen and (min-width: ${breakpoints.lg}) {
        display: ${({ showMoreFilters }) => (showMoreFilters ? "grid" : "none")};
        position: relative;
        top: auto;
        inset-inline-start: auto;
        grid-column: 1 / span 1;
        grid-row: 1 / span calc(var(--rowsLG) * 6);
        align-items: start;
        width: auto;
        height: auto;
        padding: 0;
        background-color: transparent;
        overflow: visible;
        z-index: auto;

        & > div {
            position: sticky;
            top: calc(var(--visibleHeaderHeight) + 122px); /* 122px = size StaticFilterBar */
        }
    }

    /* vp: 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        grid-row-end: span calc(var(--rowsXXL) * 6);
    }

    /* Hide on mobile */
    @media screen and (max-width: ${maxBreakpoints.md}) {
        display: none;
    }

    @supports not (inset-inline-start: 0) {
        ${getDirection("left")}: 0;

        @media screen and (min-width: ${breakpoints.lg}) {
            ${getDirection("left")}: auto;
        }
    }
`;

export const Grid = styled.main<{ showMoreFilters?: boolean }>`
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: ${theme.space[3]}px;
    justify-content: center;
    margin-top: 3px; /* Fix hidden box-shadow of tiles */
    margin-bottom: 40px;
    padding-top: ${theme.space[3]}px;

    /* vp-width: 768px */
    @media screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    /* vp-width: 1024 */
    @media screen and (min-width: ${breakpoints.lg}) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    /* vp-width: 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        grid-template-columns: repeat(4, 1fr);
    }

    /* vp-width: 1440px */
    @media screen and (min-width: ${breakpoints.xxl}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: ${theme.space[4]}px;
    }

    @media screen and (min-width: ${breakpoints.xxxl}) {
        gap: ${theme.space[4]}px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    /* vp width: 1600px */
    @media screen and (min-width: ${breakpoints.max}) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @supports not (gap: 0) {
        grid-column-gap: ${theme.space[4]}px;
        grid-row-gap: ${theme.space[4]}px;
    }
`;
