import styled from "styled-components";
import SvgIconCart from "../../../../svg/v2/lexus/cart.svg";
import SvgIconCog from "../../../../svg/lexus/aem/cog.svg";
import SvgIconControls from "../../../../svg/lexus/aem/controls.svg";
import SvgIcon360 from "../../../../svg/toyota/360.svg";
import SvgIconVideo from "../../../../svg/toyota/video.svg";
import SvgIconTag from "../../../../svg/lexus/aem/tag.svg";
import SvgIconExclamationCircle from "../../../../svg/lexus/aem/exclamationCircle.svg";
import SvgIconPlusCircle from "../../../../svg/lexus/aem/iconPlusCircle.svg";
import SvgIconHeart from "../../../../svg/lexus/aem/heart.svg";
import SvgIconHeartFilled from "../../../../svg/lexus/aem/heartFull.svg";
import SvgIconStar from "../../../../svg/lexus/aem/star.svg";
import SvgIconStarFilled from "../../../../svg/lexus/aem/starFilled.svg";
import SvgCheckmark from "../../../../svg/lexus/aem/checkmark.svg";
import SvgClose from "../../../../svg/lexus/aem/close.svg";
import SvgSort from "../../../../svg/lexus/aem/sort.svg";
import SvgArrowRight from "../../../../svg/lexus/aem/arrow-right.svg";
import SvgChevronUp from "../../../../svg/lexus/aem/chevron-up.svg";
import SvgChevronDown from "../../../../svg/lexus/aem/chevron-down.svg";
import SvgChevronLeft from "../../../../svg/lexus/aem/chevron-left.svg";
import SvgCalculator from "../../../../svg/lexus/aem/calculator.svg";
import SvgShoppingCart from "../../../../svg/lexus/aem/shoppingcart.svg";
import SvgSoldOutCart from "../../../../svg/lexus/aem/soldoutcart.svg";
import SvgChevronRight from "../../../../svg/lexus/aem/chevron-right.svg";
import SvgCompare from "../../../../svg/lexus/aem/compare.svg";
import SvgLocation from "../../../../svg/lexus/aem/location.svg";
import SvgPhone from "../../../../svg/lexus/aem/phone.svg";
import SvgReserve from "../../../../svg/lexus/aem/reserve-v2.svg";
import SvgCartV2 from "../../../../svg/lexus/aem/cartv2.svg";
import SvgCard from "../../../../svg/lexus/aem/card.svg";
import SvgInsurance from "../../../../svg/lexus/aem/insurance.svg";
import SvgMarker from "../../../../svg/lexus/aem/marker.svg";
import SvgCarSimilarCars from "../../../../svg/lexus/aem/car-similar-cars.svg";
import SvgInfo from "../../../../svg/lexus/aem/info.svg";
import SvgPlus from "../../../../svg/lexus/aem/plus.svg";
import SvgFilters from "../../../../svg/lexus/aem/filters.svg";
import SvgHybrid from "../../../../svg/lexus/hybrid.svg";
import SvgPlugin from "../../../../svg/lexus/plugin.svg";
import SvgElectric from "../../../../svg/lexus/electric.svg";
import SvgPetrolDiesel from "../../../../svg/lexus/petrolDiesel.svg";
import SvgEngine from "../../../../svg/lexus/engine.svg";

const iconVariants = {
    customerCentric: "Customer_Centric",
    profile: "Profile",
    satisfiedCustomer: "Satisfied_Customer",
    userNotification: "User_Notification",
    spin: "Spin",
    charger: "Charger",
    electricStation: "Electric_Station",
    findDealer: "Find_Dealer",
    gas: "Gas",
    globe: "Globe",
    highway: "Highway",
    language: "Language",
    location: "Location",
    map: "Map",
    mapDigital: "Map_Digital",
    noCharger: "No_Charger",
    route: "Route",
    selfCharging: "Self_Charging",
    calendar: "Calendar",
    extendedHours: "Extended_Hours",
    fast: "Fast",
    fastLaneForSpecificServices: "Fast_Lane_For_Specific_Services",
    freeTechCheck: "Free_Tech_Check",
    time: "Time",
    timeDotted: "Time_Dotted",
    callCenter: "Call_Center",
    newsletterEmail: "Newsletter_Email",
    print: "Print",
    smartphone: "Smartphone",
    website: "Website",
    wifi: "Wifi",
    commercialInsurance: "Commercial_Insurance",
    insurance: "Insurance",
    insuranceCalculator: "Insurance_Calculator",
    insuranceCustomerPortal: "Insurance_Customer_Portal",
    lock: "Lock",
    motorInsurance: "Motor_Insurance",
    check: "Check",
    compare: "Compare",
    compareLines: "Compare_Lines",
    controls: "Controls",
    customize: "Customize",
    customizeBlack: "Customize_Black",
    externalLink: "External_Link",
    flexible: "Flexible",
    fullscreen: "Fullscreen",
    hybridHealthCheck: "Hybrid_Health_Check",
    noSound: "No_Sound",
    player: "Player",
    search: "Search",
    sort: "Sort",
    sound: "Sound",
    trade: "Trade",
    trash: "Trash",
    viewOff: "View_Off",
    viewOn: "View_On",
    arrowDown: "Arrow_Down",
    arrowLeft: "Arrow_Left",
    arrowRight: "Arrow_Right",
    arrowUp: "Arrow_Up",
    chevronDown: "Chevron_Down",
    chevronLeft: "Chevron_Left",
    chevronRight: "Chevron_Right",
    chevronUp: "Chevron_Up",
    close: "Close",
    explore: "Explore",
    menu: "Menu",
    minus: "Minus",
    plus: "Plus",
    support: "Support",
    courtesyVan: "Courtesy_Van",
    customerLoyalty: "Customer_Loyalty",
    easy: "Easy",
    hand: "Hand",
    like: "Like",
    qualityAssurance: "Quality_Assurance",
    safeDriving: "Safe_Driving",
    card: "Card",
    coins: "Coins",
    excessBonus: "Excess_Bonus",
    finance: "Finance",
    money: "Money",
    moneyHand: "Money_Hand",
    piggyBank: "Piggy_Bank",
    saveMoney: "Save_Money",
    wallet: "Wallet",
    assessment: "Assessment",
    conventionalWarranty: "Conventional_Warranty",
    download: "Download",
    form: "Form",
    hybridWarranty: "Hybrid_Warranty",
    image: "Image",
    invoice: "Invoice",
    jpg: "JPG",
    link: "Link",
    manual: "Manual",
    mp4: "MP4",
    pdf: "PDF",
    pointCheck: "Point_Check",
    quality: "Quality",
    readyToGo: "Ready_To_Go",
    reserve: "Reserve",
    star: "Star",
    tyreMatch: "Tyre_Match",
    upload: "Upload",
    video: "Video",
    cloud: "Cloud",
    cloudComplete: "Cloud_Complete",
    cloudRain: "Cloud_Rain",
    co2: "CO2",
    environmental: "Environmental",
    greenPromise: "Green_Promise",
    lightning: "Lightning",
    mountainOffice: "Mountain_Office",
    tree: "Tree",
    basket: "Basket",
    shoppingBag: "Shopping_Bag",
    shoppingCart: "Shopping_Cart",
    visitsDealership: "Visits_Dealership",
    chat: "Chat",
    chatbox: "Chatbox",
    personalizedFeedback: "Personalized_Feedback",
    anticipation: "Anticipation",
    elegance: "Elegance",
    h: "H",
    myT: "MyT",
    simplicity: "Simplicity",
    bell: "Bell",
    bellNotification: "Bell_Notification",
    error: "Error",
    info: "Info",
    roadAssistance: "Road_Assistance",
    calculator: "Calculator",
    movingTool: "Moving_Tool",
    personalizedFeedbackStatistic: "Personalized_Feedback_Statistic",
    repair: "Repair",
    settings: "Settings",
    tool: "Tool",
    treat: "Treat",
    engineTemperature: "Engine_Temperature",
    errorRing: "Error_Ring",
    ev: "EV",
    meters: "Meters",
    mileage: "Mileage",
    speed: "Speed",
    speedometer: "Speedometer",
    steeringWheelAlert: "Steering_Wheel-Alert",
    airVent: "Air_Vent",
    airbag: "Airbag",
    battery: "Battery",
    engine: "Engine",
    interiorCar: "Interior_Car",
    mobility: "Mobility",
    partsSales: "Parts_Sales",
    steeringWheel: "Steering_Wheel",
    tyres: "Tyres",
    wheel: "Wheel",
    wheelSafety: "Wheel_Safety",
    accelerations: "Accelerations",
    batteryElectricCarBEV: "Battery_Electric_Car_BEV",
    car: "Car",
    carsExchange: "Cars_Exchange",
    collision: "Collision",
    dieselPetrolCar: "Diesel_Petrol_Car",
    exceptionalEvent: "Exceptional_Event",
    hardBrakes: "Hard_Brakes",
    hybridElectricCar: "Hybrid_Electric_Car_HEV",
    hydrogenCar: "Hydrogen_Car",
    lcv: "LCV",
    mildHybridCar: "Mild_Hybrid_Car_MHV",
    pickUpService: "Pick_Up_Service",
    pluginHybridCarPHEV: "Plug_In_Hybrid_Car_PHEV",
    repairsWorkshop: "Repairs_Workshop",
    roadServiceAssistance: "Road_Service_Assistance",
    sensor: "Sensor",
    theftFire: "Theft_Fire",
    vehicleReplacement: "Vehicle_Replacement",
    facebook: "Facebook",
    instagram: "Instagram",
    linkedin: "LinkedIn",
    telegram: "Telegram",
    tiktok: "TikTok",
    twitter: "Twitter",
    vk: "VK",
    whatsapp: "WhatsApp",
    yandexzen: "Yandex-Zen",
    youtube: "Youtube",
    balance: "Balance",
    carLoan: "Car_Loan",
    claim: "Claim",
    corporateCustomer: "Corporate_Customer",
    deposit: "Deposit",
    electronicWallet: "Electronic_Wallet",
    fidelityCard: "Fidelity_Card",
    fixedPayments: "Fixed_Payments",
    leasing: "Leasing",
    mobileApp: "Mobile_App",
    privateCustomer: "Private_Customer",
    securedPayment: "secured_Payment",
    tax: "Tax",
    tradeCycleManagement: "Trade_Cycle_Management",
    afterSalesFinance: "After_Sales_Finance",
};

type IconTypes = {
    variant: keyof typeof iconVariants;
    size?: "small" | "medium" | "large";
};

/**
 * Icon
 * @description Icons from TME Icon font
 */
export const Icon = styled.span.attrs<IconTypes>((props) => ({
    className: `dxp-icon icon-${iconVariants[props.variant]}-${props.size || "small"}`,
    role: "img",
    "aria-hidden": "true",
}))<IconTypes>``;

/**
 * Icon
 * @description Optimized icons from iO
 */
export const IconCart = SvgIconCart;
export const IconCog = SvgIconCog;
export const IconControls = SvgIconControls;
export const Icon360 = SvgIcon360;
export const IconVideo = SvgIconVideo;
export const IconTag = SvgIconTag;
export const IconPlusCircle = SvgIconPlusCircle;
export const IconExclamationCircle = SvgIconExclamationCircle;
export const IconHeart = SvgIconHeart;
export const IconHeartFilled = SvgIconHeartFilled;
export const IconStar = SvgIconStar;
export const IconStarFilled = SvgIconStarFilled;
export const IconCheckmark = SvgCheckmark;
export const IconClose = SvgClose;
export const IconArrowRight = SvgArrowRight;
export const IconChevronUp = SvgChevronUp;
export const IconChevronDown = SvgChevronDown;
export const IconCalculator = SvgCalculator;
export const IconShoppingCart = SvgShoppingCart;
export const IconSoldOutCart = SvgSoldOutCart;
export const IconSort = SvgSort;
export const IconCompare = SvgCompare;
export const IconChevronLeft = SvgChevronLeft;
export const IconChevronRight = SvgChevronRight;
export const IconLocation = SvgLocation;
export const IconTradeIn = SvgCompare;
export const IconPhone = SvgPhone;
export const IconReserve = SvgReserve;
export const IconCartV2 = SvgCartV2;
export const IconCard = SvgCard;
export const IconInsurance = SvgInsurance;
export const IconMarker = SvgMarker;
export const IconCarSimilarCars = SvgCarSimilarCars;
export const IconInfo = SvgInfo;
export const IconPlus = SvgPlus;
export const IconFilters = SvgFilters;
export const IconHybrid = SvgHybrid;
export const IconPlugin = SvgPlugin;
export const IconElectric = SvgElectric;
export const IconPetrolDiesel = SvgPetrolDiesel;
export const IconEngine = SvgEngine;

// Legacy icon
export const IconRainbowCircle = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAHgUExURUdwTJ+qv6+Pn/S0Op+vz8/fb+9vL++/P+9fP/+fL6qftPC1OfSsNfC6O7DNhvJsObPPg7nRe/BpO+paQ/RvNfegLuvDQbt/ketfQexgQOvDQK2Tp/NzNPWjMeNHT8TUdN9PT/BrOcPVc/NuN99LU6/Pj/SqL6Ojue/PP+nLRenJRKHHnaLHnfd/K+VMTPmVK81lc+lXRcVwgK2VqpTEv6zMjL18jprFqt9PX/uJKvmTK/mEKvuMK79/n5/Pj9lSXOfPR4/B2dvbVuTXSpTByZS30vmCLZquyJPDz93bU4+/2/d5MPd/L/h7MPV0NJK71pC92fd8MJ6qwtPYX/d5Mfd/L8fWbZnEstTYYOTZSufTR+jPRo+/2fd8L5S41Ph+L5DB2/h9L/G0OPStNe+7PLrRfLLPhedSSPJtOe5jP+9nPPNwN+hUR+7APsLUdeRJTfamMutdQvRyNvieLqOju+vHQt1PWaics7GPpM5lc7aIm6bKmavMkNxRW/qPK8F3iNFgbfuGK+RKTfmYLejNRubWSZ3GppTCzdjaW+PbTvmBLZC+2pO71pbDu5C/2+fQR+fQSMvXa+HcUfZ4MvuHKpa20Jmyy+bVSfV2NJTCy+nMRdDZZZ6qwvmALZfEtvd7MLMGcW0AAABfdFJOUwAwEDAQEBAQEBAwn+/fn1BA79/fMO+AgIDv7++fn0AwEJ+f70AQMO8Qj++A70BQgI+P358w3+/vEI/vMN8QEGBAYN9gYI9g70CPQI8g3+/fj4+fcO9A74/vMEBQMGDv71sSmQAAAOpJREFUGNNjYMAHrHVDfVBFOPVtGufGJ/p6syHE1ESb66Z0xSfOn+wnAhNj5ikotrJ0iw0MmVGkzA8RY+dNSpblBLHYZLIzhDjAgmK5KdwwTYpZmRJghlapFDtMkEOyUBhEa7TM0kTYaZiTrgCkHCb0RyME9Zry5YBU1PRWZ4SgfW2ZCpCK7JzkiRA0qGywANFOHRGsMDFbs3ojMCN49kQlmKBxRZU4mMEalpfKZwdisQSVlEszQaTlBdNqdMJdHAPips1R54JpEjCtbps3M6G7Z6o7I8JOVW3z3vaEPn9XFtQgNfHwisEbCwCw0jL4tzcnHAAAAABJRU5ErkJggg==);
    background-size: 10px 10px;
`;

type IconArrowColorType = { colorVariant?: "azure" | "black" | "brushed" | "disabled" };

export const IconArrowLeftLong = styled.span.attrs<IconArrowColorType>((props) => ({
    className: `l-icon l-arrow-left-icon${props.colorVariant ? ` l-arrow-icon-${props.colorVariant}` : ""}`,
}))<IconArrowColorType>``;

export const IconArrowRightLong = styled.span.attrs<IconArrowColorType>((props) => ({
    className: `l-icon l-arrow-icon${props.colorVariant ? ` l-arrow-icon-${props.colorVariant}` : ""}`,
}))<IconArrowColorType>``;

export default Icon;
