import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { breakpoints } from "../../../../../../shared-logic/themes/lexusTheme";

export const Wrapper = styled.div<{ show: boolean }>`
    transition: opacity 0.15s;
    opacity: ${(props) => (props.show ? 1 : 0)};

    @media screen and (min-width: ${breakpoints.xl}) {
        margin: 0 auto;
        text-align: center;
    }
`;

export const Value = styled.span`
    font-family: ${theme.fonts.regular};
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    line-height: 1.6;
    color: ${theme.colors.midnight};
`;

export const Label = styled.span`
    font-family: ${theme.fonts.book};
    font-size: 1.8rem;
    letter-spacing: 0.1rem;
    line-height: 1.6;
`;
