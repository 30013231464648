import React, { Ref } from "react";
import * as Tooltip from "../../../../styles/v2/lexus/components/Tooltip";
import Icon from "../../../../styles/v2/lexus/globals/Icon";
import { PopoverTooltipType, usePopOverTooltip } from "../../../../hooks/usePopoverTooltip";

// Tooltip used in the Popover component. Can possibly be used with other components as well.
const PopoverTooltip = React.forwardRef((props: PopoverTooltipType, forwardedRef?: Ref<HTMLElement>): JSX.Element => {
    const {
        small,
        children,
        hide,
        onClose,
        useHTML = false,
        content = "",
        openedOnHover = false,
        forceShowModal,
    } = props;

    const { tooltipVisible, tooltipRef, modalVisible, pointOffset, show, offsetWithArrowLocation } = usePopOverTooltip(
        props,
        forwardedRef,
        forceShowModal,
    );

    return (
        <Tooltip.BoxWrapper onClick={(event) => event.stopPropagation()} hide={!show}>
            <Tooltip.Wrapper
                role="tooltip"
                ref={tooltipRef}
                absolute
                visible={!hide && show && !modalVisible && (tooltipVisible || !!children)}
                point={pointOffset}
                offset={offsetWithArrowLocation}
                small={small}
                disabled={modalVisible}
            >
                <Tooltip.Content>
                    {content && useHTML ? <span dangerouslySetInnerHTML={{ __html: content }} /> : content}
                    {children}
                </Tooltip.Content>
                {!openedOnHover && (
                    <Tooltip.Close
                        onClick={(event) => {
                            event.stopPropagation();
                            onClose();
                        }}
                    >
                        <Icon variant="close" />
                    </Tooltip.Close>
                )}
            </Tooltip.Wrapper>
        </Tooltip.BoxWrapper>
    );
});

export default PopoverTooltip;
