import styled, { css } from "styled-components";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { theme } from "../theme/lexusTheme";

export const Wrapper = styled.div<{ isSmall?: boolean }>`
    display: flex;
    position: relative;
    margin-bottom: ${theme.space[2]}px;
    padding: 0.5rem;
    white-space: nowrap;
    background: ${({ isSmall }) => (isSmall ? theme.colors.grey7 : theme.colors.grey2)};
    vertical-align: middle;
    @media ${getBreakpoint("up", "lg")} {
        white-space: normal;
    }
`;

export const Tab = styled.button<{ isActive?: boolean; isSmall?: boolean; centered?: boolean }>`
    flex: 0 0 50%;
    padding: ${({ centered }) => (centered ? "10px" : "16px 14px")};
    text-align: ${({ centered }) => (centered ? "center" : getDirection("left"))};
    color: ${({ isSmall }) => (isSmall ? theme.colors.white : theme.colors.platinumDark)};
    background: ${theme.colors.silver};
    ${({ isActive }) =>
        isActive &&
        css`
            color: ${theme.colors.midnight};
            background: ${theme.colors.white};
        `};
`;
