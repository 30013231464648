import styled, { css } from "styled-components";
import { theme } from "../../../styles/v2/lexus/theme/lexusTheme";

export const Wrapper = styled.div<{ variantOutline?: boolean; isPdf?: boolean }>`
    display: flex;
    position: sticky;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: ${theme.space[3] / 2}px ${theme.space[3]}px;
    font-family: ${theme.fonts.book};
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    color: ${theme.colors.platinumDark};
    background-color: ${theme.colors.silver};

    ${({ variantOutline }) =>
        variantOutline &&
        css`
            background-color: transparent;
            border: 1px solid ${theme.colors.platinumLight};
        `};

    ${({ isPdf }) =>
        isPdf &&
        css`
            margin: ${theme.space[1]}px 0;
        `};
`;
