import styled, { css } from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { breakpoints } from "../../../../../shared-logic/themes/lexusTheme";

export const NoResultWrapper = styled.div<{ showMoreFilters?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* vp-width: 768px */
    @media screen and (min-width: ${breakpoints.md}) {
        grid-column: span 2;

        ${({ showMoreFilters }) =>
            showMoreFilters &&
            css`
                grid-column: span 1;
            `};
    }
    /* vp-width: 1024 */
    @media screen and (min-width: ${breakpoints.lg}) {
        grid-column: span 3;

        ${({ showMoreFilters }) =>
            showMoreFilters &&
            css`
                grid-column: span 2;
            `};
    }

    /* vp-width: 1200px */
    @media screen and (min-width: ${breakpoints.xl}) {
        grid-column: span 4;

        ${({ showMoreFilters }) =>
            showMoreFilters &&
            css`
                grid-column: span 3;
            `};
    }
`;

export const NoResultTextWrapper = styled.div`
    margin: ${theme.space[3]}px 0;
    text-align: center;
`;

export const NoResultMessage = styled.div`
    font-family: ${theme.fonts.regular};
`;

export const NoResultDescription = styled.div`
    font-family: ${theme.fonts.book};
`;
