import styled from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import { breakpoints } from "../../../../../shared-logic/themes/lexusTheme";
import { h3Style } from "../../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import * as ContainerStyle from "../../../../../../common-deprecated/styles/v2/lexus/globals/Container";

export const Wrapper = styled(ContainerStyle.default)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: ${theme.space[3]}px !important;
    padding-right: ${theme.space[3]}px !important;
`;

export const Container = styled.div`
    width: 100%;
    padding: ${theme.space[3]}px;
    background-color: ${theme.colors.white};

    @media screen and (min-width: ${breakpoints.max}) {
        padding: ${theme.space[4]}px;
    }
`;

export const Title = styled.h2`
    ${h3Style};
    margin: 0;
    padding: ${theme.space[5]}px 0 ${theme.space[3]}px 0;
    text-align: center;
    color: ${theme.colors.black};

    @media screen and (min-width: ${breakpoints.lg}) {
        padding: 56px 0 ${theme.space[5]}px 0;
    }
`;

export const FilterWrapper = styled.article`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.space[3]}px;
    padding: ${theme.space[3]}px;
    background-color: ${theme.colors.white};

    @supports not (gap) {
        > div:not(:nth-last-child(1)) {
            margin-${getDirection("right")}: ${theme.space[3]}px;
        }
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        grid-template-columns: 1fr 238px;
    }
`;
