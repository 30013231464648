import React, { useEffect, useMemo, useRef } from "react";
import {
    SliderButtonType,
    SliderValueOrderType,
} from "../../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import useButtonSlider from "../../../../../../../shared-logic/features/filters/hooks/useButtonSlider";
import { CommonLabelType, useCommonLabel } from "../../../../../../utils/commonLabels";
import * as Styles from "./ButtonSlider.styles";

export type ButtonSliderType = {
    order: SliderValueOrderType;
    minValue: number;
    maxValue: number;
    enableMinValue?: boolean;
    currentMinValue?: number;
    currentMaxValue: number;
    step: number;
    setValue: (value: number, type: SliderButtonType) => void;
    getValueLabel?: (max: number, min: number) => string;
    setValueLabel?: (label: string) => void;
    getSecondaryValueLabel?: (max: number, min: number) => string; // Used for secondary price in dual currency
    setSecondaryValueLabel?: (label: string) => void; // Used for secondary price in dual currency
    filterName?: string;
    // Aria overwrites should only be used for RTL support. Fallbacks already provided if not RTL
    ariaValues?: {
        ariaMinValue?: number;
        ariaMaxValue?: number;
        ariaMinLabelKey: CommonLabelType;
        ariaMaxLabelKey: CommonLabelType;
        ariaMinTitleLabelKey: CommonLabelType;
        ariaMaxTitleLabelKey: CommonLabelType;
    };
    showValueLabel?: boolean;
    // Callback to set the slider values in the parent component
    setSliderValues?: (min: number, max: number) => void;
};

/**
 * This is the common AEM Lexus Button slider used across the Car Filter and Model Filter.
 */
const ButtonSlider = (props: ButtonSliderType): JSX.Element => {
    const {
        enableMinValue,
        minValue,
        maxValue,
        step,
        order,
        currentMaxValue,
        currentMinValue,
        filterName,
        setSliderValues,
    } = props;

    const { ariaMinValue, ariaMaxValue, ariaMinLabelKey, ariaMaxLabelKey, ariaMinTitleLabelKey, ariaMaxTitleLabelKey } =
        props.ariaValues || {
            ariaMinValue: currentMinValue,
            ariaMaxValue: currentMaxValue,
            ariaMinLabelKey: "ariaSliderMinValue",
            ariaMaxLabelKey: "ariaSliderMaxValue",
            ariaMinTitleLabelKey: "ariaSliderMinValueTitleLabel",
            ariaMaxTitleLabelKey: "ariaSliderMaxValueTitleLabel",
        };

    const { setValue, getValueLabel, setValueLabel, getSecondaryValueLabel, setSecondaryValueLabel, showValueLabel } =
        props;

    const sliderBarRef = useRef<HTMLDivElement>(null);
    const { getValueFromXPos, getOnMouseDown, getOnTouchStart, getOnKeyDown, xPositionLeft, xPositionRight } =
        useButtonSlider(
            sliderBarRef,
            order,
            maxValue,
            minValue,
            step,
            setValue,
            currentMaxValue,
            currentMinValue,
            Styles.buttonSize,
            enableMinValue,
        );

    const valueLabel =
        getValueLabel && !setValueLabel
            ? getValueLabel(getValueFromXPos(xPositionRight), enableMinValue ? getValueFromXPos(xPositionLeft) : 0)
            : "";

    const sliderMinValueLabel = useCommonLabel(ariaMinLabelKey, { value: ariaMinValue });
    const sliderMaxValueLabel = useCommonLabel(ariaMaxLabelKey, { value: ariaMaxValue });
    const sliderMinValueTitleLabel = useCommonLabel(ariaMinTitleLabelKey, { value: filterName });
    const sliderMaxValueTitleLabel = useCommonLabel(ariaMaxTitleLabelKey, { value: filterName });

    const xPositionLowest = Math.min(xPositionLeft, xPositionRight);
    const xPositionHighest = Math.max(xPositionLeft, xPositionRight);

    useEffect(() => {
        // Update value label
        if (setValueLabel && getValueLabel) {
            setValueLabel(
                getValueLabel(
                    getValueFromXPos(xPositionHighest),
                    enableMinValue ? getValueFromXPos(xPositionLowest) : 0,
                ),
            );
        }

        setSliderValues?.(enableMinValue ? getValueFromXPos(xPositionLowest) : 0, getValueFromXPos(xPositionHighest));

        // Update secondary value label (dual currency)
        if (setSecondaryValueLabel && getSecondaryValueLabel) {
            setSecondaryValueLabel(
                getSecondaryValueLabel(
                    getValueFromXPos(xPositionHighest),
                    enableMinValue ? getValueFromXPos(xPositionLowest) : 0,
                ),
            );
        }
    }, [currentMinValue, currentMaxValue, xPositionHighest, xPositionLowest, enableMinValue]);

    return useMemo(
        () => (
            <>
                {valueLabel && showValueLabel && (
                    <Styles.Value>
                        <span dangerouslySetInnerHTML={{ __html: valueLabel }} />
                    </Styles.Value>
                )}
                <Styles.Wrapper ref={sliderBarRef}>
                    {enableMinValue && (
                        <Styles.Handle
                            type="button"
                            onMouseDown={getOnMouseDown("min")}
                            onTouchStart={getOnTouchStart("min")}
                            onKeyDown={getOnKeyDown("min")}
                            left={xPositionLeft}
                            title={sliderMinValueLabel}
                            role="slider"
                            aria-orientation="horizontal"
                            aria-valuenow={ariaMinValue}
                            aria-valuemin={minValue}
                            aria-valuemax={maxValue}
                            aria-label={sliderMinValueTitleLabel}
                            tabIndex={0}
                        />
                    )}
                    <Styles.Track
                        left={`${enableMinValue ? xPositionLowest : -Styles.buttonSize / 2}px`}
                        width={`${xPositionHighest - xPositionLowest + (enableMinValue ? 0 : Styles.buttonSize / 2)}px`}
                    />
                    <Styles.Handle
                        type="button"
                        onMouseDown={getOnMouseDown("max")}
                        onTouchStart={getOnTouchStart("max")}
                        onKeyDown={getOnKeyDown("max")}
                        left={xPositionRight}
                        title={sliderMaxValueLabel}
                        role="slider"
                        aria-orientation="horizontal"
                        aria-valuenow={ariaMaxValue}
                        aria-valuemin={minValue}
                        aria-valuemax={maxValue}
                        aria-label={sliderMaxValueTitleLabel}
                        tabIndex={0}
                    />
                </Styles.Wrapper>
            </>
        ),
        [enableMinValue, getOnMouseDown, getOnTouchStart, valueLabel, xPositionLeft, xPositionRight],
    );
};

export default ButtonSlider;
