import React from "react";
import { CarFilterIdType, StockCarFilterId, UsedCarFilterId } from "../../../../shared-logic/types/UscCommonTypes";
import MinMaxSliderFilter from "./min-max/MinMaxSliderFilter";
import DefaultMCFilter from "./multiple-choice/DefaultMCFilter";
import PriceFilter from "./price/PriceFilter";
import ColourFilter from "./colour/ColourFilter";
import MileageFilter from "./mileage/MileageFilter";
import WarrantyFilter from "./warranty/WarrantyFilter";
import EcoLabelFilter from "./eco-label/EcoLabelFilter";
import * as Styles from "./styles/FilterWrapperStyles";
import LocationFilter from "./location/LocationFilter";
import { isMinMaxSlider, isMultipleChoice, isPrice } from "../../../utils/filters";
import EfficiencyClassFilter from "./efficiency-class/EfficiencyClassFilter";
import ModelFilter from "./model/ModelFilter";

const FilterWrapper = (props: { filterId: CarFilterIdType }): JSX.Element => {
    const { filterId } = props;

    let component = null;
    // Unique filter components.
    if (filterId === UsedCarFilterId.Colour || filterId === StockCarFilterId.Colour) {
        component = <ColourFilter filterId={filterId} />;
    }

    if (isPrice(filterId)) component = <PriceFilter filterId={filterId} />;
    if (filterId === UsedCarFilterId.Mileage) component = <MileageFilter />;
    if (filterId === UsedCarFilterId.Warranty) component = <WarrantyFilter />;
    if (filterId === UsedCarFilterId.EcoLabel) component = <EcoLabelFilter />;
    if (filterId === UsedCarFilterId.Location || filterId === StockCarFilterId.Location) {
        component = <LocationFilter filterId={filterId} />;
    }
    if (filterId === UsedCarFilterId.EfficiencyClass) component = <EfficiencyClassFilter />;
    if (filterId === UsedCarFilterId.Model || filterId === StockCarFilterId.Model) component = <ModelFilter />;

    // Default filter components.
    if (!component) {
        if (isMinMaxSlider(filterId)) component = <MinMaxSliderFilter filterId={filterId} />;
        if (isMultipleChoice(filterId)) component = <DefaultMCFilter filterId={filterId} />;
    }

    return <Styles.Wrapper>{component}</Styles.Wrapper>;
};

export default FilterWrapper;
