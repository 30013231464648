import React, { useRef, useState } from "react";
import { createPortal } from "react-dom";
import * as DOMUtils from "history/DOMUtils";
import { IconInfo } from "../../../../styles/v2/lexus/globals/Icon";
import * as Styles from "../../../../styles/v2/lexus/components/Tooltip";
import PopoverTooltip from "./PopoverTooltip";
import { useShouldShowModal } from "../../../../hooks/useShouldShowModal";
import type { PopoverType } from "../Popover";
import { SrOnly } from "../../../../styles/v2/lexus/utilities/ScreenreaderOnly";
import { IconWrapper } from "../../../../styles/v2/lexus/globals/IconWrapper";
import useClientSideRender from "../../../../hooks/useClientSideRender";

/**
 * Render an info icon with a tooltip. Show a modal if there isn't enough screen space for the tooltip.
 */
const Popover = (props: PopoverType): JSX.Element => {
    const { content, useHTML, children, initializeTooltipAlignment = true, forceShowModal } = props;
    let { showOnClick } = props;
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const isClient = useClientSideRender();
    const iconRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<HTMLElement>(null);

    // If a modal needs to be rendered the trigger for the modal should always be onClick and not hover.
    // This is why useShouldShowModal is also used here as this behaviour needs to be determined here while the other logic happens in PopoverTooltip
    const { modalVisible } = useShouldShowModal(tooltipRef, showOnClick);
    if (modalVisible) showOnClick = true;

    const onClose = (): void => setShowTooltip(false);

    // Necessary to fix hydration errors
    if (!isClient) {
        return <div />;
    }

    return (
        <div onMouseLeave={showOnClick ? undefined : onClose} data-testid="common-popover">
            <Styles.PopoverButton
                ref={iconRef}
                onClick={(event) => {
                    event.stopPropagation();
                    if (showOnClick) setShowTooltip(!showTooltip);
                }}
                onFocus={(event) => {
                    event.stopPropagation();
                    if (!showOnClick) setShowTooltip(true);
                }}
                onBlur={(event) => {
                    event.stopPropagation();
                    if (!showOnClick) setShowTooltip(false);
                }}
                onMouseEnter={() => {
                    if (!showOnClick) setShowTooltip(true);
                }}
            >
                <IconWrapper size="lg" color="midnight">
                    <IconInfo />
                </IconWrapper>
                {content && <SrOnly dangerouslySetInnerHTML={{ __html: content }} />}
                {showTooltip && <Styles.HitBoxButtonSpacer />}
            </Styles.PopoverButton>
            {DOMUtils.canUseDOM &&
                createPortal(
                    <PopoverTooltip
                        hide={!showTooltip}
                        ref={tooltipRef}
                        content={content}
                        useHTML={useHTML}
                        openedOnHover={!showOnClick}
                        onClose={onClose}
                        iconRef={iconRef}
                        initializeTooltipAlignment={initializeTooltipAlignment}
                        forceShowModal={forceShowModal}
                    >
                        {children}
                    </PopoverTooltip>,
                    document.body,
                )}
        </div>
    );
};

export default Popover;
