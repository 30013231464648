import React from "react";
import { ModalViewType } from "../../../../../common-deprecated/types/CommonTypes";
import { CarFilterModalType } from "../../../utils/modalConstants";
import { useCarFilterSelector } from "../../../redux/store";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import { getResultsLabel } from "../../../utils/helpers";
import FilterSavedCars from "../filters/FilterSavedCars";
import ActiveFilterSliderContainer from "../active-filters/slider/ActiveFilterSliderContainer";
import FilterList from "../filters/FilterList";
import ModalBaseView from "../../../../shared-components/lexus/modals/Modal";
import ButtonPrimary from "../../../../../common-deprecated/components/aem/lexus/button/ButtonPrimary";
import { IconClose } from "../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import * as ModalStyles from "../../../../shared-components/lexus/modals/Modal.styles";
import * as Styles from "./CarFiltersModal.styles";

const CarFiltersModal = (props: ModalViewType<CarFilterModalType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const resultCount = useCarFilterSelector((state) => state.carResults.totalResultCount);
    const seeResultsLabel = useCarFilterLabel(getResultsLabel(resultCount), { value: resultCount });
    const titleLabel = useCarFilterLabel("carFilterAllFilters");

    return (
        <ModalBaseView modalIdType={modalSettings.type} show={show} onClose={close} size="medium">
            <ModalStyles.Header>
                <Styles.Title>{titleLabel}</Styles.Title>
                <ModalStyles.Button type="button" onClick={close}>
                    <IconClose />
                </ModalStyles.Button>
            </ModalStyles.Header>
            <ModalStyles.Body noSpacing>
                <Styles.FilterSavedCarsWrapper>
                    <FilterSavedCars />
                </Styles.FilterSavedCarsWrapper>
                <Styles.FilterActiveFiltersWrapper>
                    <ActiveFilterSliderContainer />
                </Styles.FilterActiveFiltersWrapper>
                <FilterList />
                <Styles.ModalFooter>
                    <ButtonPrimary fullWidth onClick={close}>
                        {seeResultsLabel}
                    </ButtonPrimary>
                </Styles.ModalFooter>
            </ModalStyles.Body>
        </ModalBaseView>
    );
};

export default CarFiltersModal;
