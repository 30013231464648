import styled, { css } from "styled-components";
import { OffsetAlignType } from "../../../../hooks/usePopoverTooltip";
import { getBreakpoint, getDirection } from "../../../../themes/common";
import { bodyStyle } from "../globals/Type";
import { theme, ColorType } from "../theme/lexusTheme";
import * as Button from "./Button";

export const ARROW_HORIZONTAL_OFFSET = 30;
export const ARROW_SIZE = 9;

/**
 * Since iOS version 13 (iPadOS), Safari started zooming out when elements are rendered outside of the viewport
 * This breaks the logic of our dynamic popover which is only moved to a valid position after the first render, causing Safari to zoom out.
 *
 * To fix this we wrap the tooltip in a div with width=0 on initial render,
 * since the actual tooltip also has a fixed width this does not interfere with the position calculations.
 *
 * See OR-3596
 */
export const BoxWrapper = styled.div<{ hide?: boolean }>`
    width: ${({ hide }) => (hide ? "0px" : "initial")};
    overflow: ${({ hide }) => (hide ? "hidden" : "initial")};
`;

type TooltipPropsType = {
    absolute?: boolean;
    point?: OffsetAlignType;
    visible?: boolean;
    offset?: { x: number; y: number };
    small?: boolean;
    disabled?: boolean;
    zIndex?: number;
};

const SmallTooltipStyles = css`
    min-width: 200px;
    padding: ${theme.space[2]}px;
`;

const NormalTooltipStyles = css`
    width: 300px;
    padding: ${(props) => props.theme.spacer}px;
    @media ${getBreakpoint("up", "sm")} {
        width: 325px;
    }
`;

export const Wrapper = styled.div<TooltipPropsType>`
    display: ${({ disabled }) => (disabled ? "none" : "block")};
    position: ${({ absolute }) => (absolute ? "fixed" : "relative")};
    min-width: 250px;
    padding: 20px 30px;
    font-family: ${theme.fonts.book};
    font-size: 13px;
    font-weight: 400;
    line-height: 1.8;
    text-align: ${getDirection("left")};
    text-shadow: none;
    text-transform: none;
    color: ${theme.colors.white};
    background: ${theme.colors.black};
    box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.25);
    transition: opacity 0.15s;
    pointer-events: ${(props) => (props.visible === undefined || props.visible === true ? "auto" : "none")};
    opacity: ${(props) => (props.visible === undefined || props.visible === true ? 1 : 0)};
    z-index: ${(props) => (props.zIndex ? props.zIndex : theme.zIndex.tooltip)};

    ${({ offset }) =>
        offset &&
        css`
            top: ${offset.y}px;
            left: ${offset.x}px;
        `};

    ${(props) => (props.small ? SmallTooltipStyles : NormalTooltipStyles)};

    ${({ point }) =>
        point &&
        css`
            /* Add a margin for the pointer mouseLeave events. */
            margin-bottom: ${ARROW_SIZE + 20}px;
            &::after {
                display: block;
                position: absolute;
                ${point.horizontal}: ${ARROW_HORIZONTAL_OFFSET}px;
                ${point.vertical}: ${-ARROW_SIZE}px;
                height: 0;
                width: 0;
                content: " ";
                border-${getDirection("left")}: ${ARROW_SIZE}px solid transparent;
                border-${getDirection("right")}: ${ARROW_SIZE}px solid transparent;
                border-${point.vertical === "top" ? "bottom" : "top"}: ${ARROW_SIZE}px solid ${theme.colors.black};
                }
        `};

    p {
        text-indent: 0;
    }

    a {
        color: ${theme.colors.white};
    }
`;

/* "pointer-events: none" is necessary on the content of a tooltip because pointer events would break the close modal flow. */
export const Content = styled.div`
    pointer-events: none;
`;

export const Close = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-top: ${theme.space[2]}px;
    margin-${getDirection("left")}: auto;
    background-color: ${theme.colors.white};
    cursor: pointer;
`;

export const HitBoxButtonSpacer = styled.div`
    position: absolute;
    top: -10px;
    width: 50px;
    height: 50px;
    ${getDirection("left")}: -15px;
`;

export const PopoverButton = styled(Button.Link)<{ iconColor?: ColorType }>`
    display: flex;
    position: relative;
    margin-${getDirection("left")}: 6px;
    padding: 0;
    padding-${getDirection("right")}: 0;
    vertical-align: initial;

    .icon {
        color: ${({ iconColor }) =>
            iconColor ? theme.colors[iconColor] || theme.colors.platinumDark : theme.colors.platinumDark};
    }
`;

/**
 * Workaround to proper align a tooltip next to a label
 */
export const Label = styled.div``;

export const TooltipWrapper = styled.div<{ width?: number }>`
    display: inline-flex;

    ${Label} {
        flex: 0 0 ${({ width }) => width || 200}px;
    }

    ${PopoverButton} {
        .icon::before {
            position: relative;
            top: -1px;
        }
    }
`;

/**
 * Tooltip button
 */
export const TooltipButton = styled.button<{ isSmall?: boolean }>`
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${theme.space[1]}px;
    color: ${theme.colors.midnight};
    ${bodyStyle};

    & > span:first-of-type {
        text-align: start;
    }

    /* Fix position icons in tooltip */
    .icon-info {
        position: relative;
        top: 1px;

        &::before {
            font-size: 1.8rem;
        }
    }
`;
