import React from "react";
import * as Styles from "./style/CarResultPriceStyles";
import { MonthlyPriceLabelKey } from "../../../../../../common-deprecated/priceUtils";
import { FinanceInfoType } from "../../../../../../common-deprecated/types/CommonTypes";
import { useCarFilterSelector } from "../../../../redux/store";
import {
    getCarFilterAemMonthlyRateFormat,
    showCarFilterAemMonthlyRates,
} from "../../../../utils/carFilterFinanceSettingUtils";
import { formatMonthlyFinancePrice } from "../../../../../shared-logic/utils/uscPriceUtils";
import { CarFilterDisclaimerType } from "../../../../utils/disclaimerUtils";
import DisclaimerIconWrapper from "../../common/DisclaimerIconWrapper";
import * as Tooltip from "../../../../../../common-deprecated/styles/v2/lexus/components/Tooltip";
import LinkTextDisclaimer from "../../common/LinkTextDisclaimer";

type CarResultMonthlyPriceType = {
    vehicleForSaleId: string;
    monthlyPrice: number;
    financeInfo: FinanceInfoType | null;
};

const CarResultMonthlyPrice = (props: CarResultMonthlyPriceType): JSX.Element | null => {
    const { monthlyPrice, financeInfo, vehicleForSaleId } = props;
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const context = useCarFilterSelector((state) => state.carFilters.currentFilter);

    const monthlyEnabled = showCarFilterAemMonthlyRates(commonSettings, carFilterSettings, context);
    if (!monthlyEnabled) return null;

    const monthlyPriceFormat = getCarFilterAemMonthlyRateFormat(commonSettings, context);
    const { monthlyFinanceLabels, euriborFinanceLabels } = financeInfo
        ? formatMonthlyFinancePrice(monthlyPrice, commonSettings, financeInfo, monthlyPriceFormat)
        : { monthlyFinanceLabels: [], euriborFinanceLabels: [] };

    return (
        <Styles.MonthlyWrapper>
            {monthlyFinanceLabels.map(({ key, value }) => {
                switch (key) {
                    case MonthlyPriceLabelKey.Price:
                        return (
                            <Styles.Monthly key={key}>
                                <Tooltip.TooltipButton>
                                    <span dangerouslySetInnerHTML={{ __html: value }} />
                                    <DisclaimerIconWrapper
                                        vehicleForSaleId={vehicleForSaleId}
                                        type={CarFilterDisclaimerType.Finance}
                                    />
                                </Tooltip.TooltipButton>
                            </Styles.Monthly>
                        );

                    case MonthlyPriceLabelKey.Disclaimer:
                        return (
                            <Styles.LegalText key={key}>
                                <LinkTextDisclaimer
                                    vehicleForSaleId={vehicleForSaleId}
                                    type={CarFilterDisclaimerType.Finance}
                                />
                            </Styles.LegalText>
                        );

                    default:
                        return <Styles.LegalText dangerouslySetInnerHTML={{ __html: value }} key={key} />;
                }
            })}

            {/* Euribor values are combined into a single label */}
            {euriborFinanceLabels && euriborFinanceLabels.length > 0 && (
                <Styles.LegalText>
                    {euriborFinanceLabels.map(({ value }) => (
                        <span key={value}>{value}</span>
                    ))}
                </Styles.LegalText>
            )}
        </Styles.MonthlyWrapper>
    );
};

export default CarResultMonthlyPrice;
