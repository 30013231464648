import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Group = styled.div`
    display: flex;
    position: absolute;
    top: ${theme.space[2]}px;
    right: ${theme.space[2]}px;
    flex-direction: column;
    gap: ${theme.space[1]}px;
`;

export const Badge = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 7px 13px;
    font-family: ${theme.fonts.regular};
    font-size: 1.4rem;
    letter-spacing: 0.014rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: ${theme.colors.white};
    background: ${theme.colors.steel};
    border-radius: 2rem;
    opacity: 0.8;
    z-index: ${theme.zIndex.sticky};
`;
