import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { CarFilterDispatchType, useCarFilterSelector } from "../../../../redux/store";
import PriceFilterSlider from "../PriceFilterSlider/PriceFilterSlider";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import * as FinanceToggle from "../../../../../../common-deprecated/styles/v2/lexus/components/FinanceToggle";
import { PriceFilterIdType } from "../../../../utils/constants/filterConstants";
import { setPrice, setPriceActive } from "../../../../redux/actions/CarFiltersActions";
import { getAemCarFilterTrackParamsSelector, trackAemCarFilterValue } from "../../../../utils/tracking";
import usePriceFilter from "../../../../hooks/usePriceFilter";

type PriceFilterType = { filterId: PriceFilterIdType };
const PriceFilter = ({ filterId }: PriceFilterType): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(filterId), shallowEqual);
    const priceFilter = useCarFilterSelector((state) => state.carFilters[filterId]);
    const activeFilter = useCarFilterSelector((state) =>
        state.carFilters[filterId].monthly.active ? "monthly" : "cash",
    );
    const { showToggle } = usePriceFilter(priceFilter, activeFilter);
    const [cashLabel, monthlyLabel] = useCarFilterLabel(["carFilterPriceCash", "carFilterPriceMonthly"]);

    return (
        <div>
            {showToggle && (
                <FinanceToggle.Wrapper fullWidth isMonthlyActive={activeFilter === "monthly"}>
                    <FinanceToggle.ToggleItem
                        isActive={activeFilter === "cash"}
                        onClick={() => dispatch(setPriceActive(filterId, "cash"))}
                    >
                        <FinanceToggle.LineClamp>{cashLabel}</FinanceToggle.LineClamp>
                    </FinanceToggle.ToggleItem>
                    <FinanceToggle.ToggleItem
                        isActive={activeFilter === "monthly"}
                        onClick={() => dispatch(setPriceActive(filterId, "monthly"))}
                    >
                        <FinanceToggle.LineClamp>{monthlyLabel}</FinanceToggle.LineClamp>
                    </FinanceToggle.ToggleItem>
                </FinanceToggle.Wrapper>
            )}
            <div>
                <PriceFilterSlider
                    activeFilterType={activeFilter}
                    priceFilter={priceFilter}
                    setValue={(type, value, valueFinanceOption) => {
                        dispatch(setPrice(filterId, type, value, valueFinanceOption));
                        trackAemCarFilterValue(trackParams, `${type}:${value}`);
                        dispatch(setPriceActive(filterId, valueFinanceOption));
                    }}
                />
            </div>
        </div>
    );
};

export default PriceFilter;
