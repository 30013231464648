import { ModalMapType } from "../../../../types/CommonTypes";
import {
    CommonModalIdsType,
    DebugModalIdsType,
    MODAL_CACHE_CLEAR,
    MODAL_CONTENT,
    MODAL_ERRORS,
    MODAL_FINANCE_DISCLAIMER,
    MODAL_OK,
    MODAL_REQUEST_LOG,
    MODAL_TOOLS,
    MODAL_VERSION,
} from "../../../../utils/modalConstants";
import OkModal from "./OkModal";
import ContentModal from "./ContentModal";
import FinanceDisclaimerModal from "./FinanceDisclaimerModal";
import ErrorsModal from "../../status/ErrorsModal";
import ToolsModal from "../../../../features/new-cars/debug-modal/components/ToolsModal";
import VersionModal from "../../../../features/new-cars/debug-modal/components/VersionModal";
import ClearCacheModal from "../../../../features/new-cars/debug-modal/components/ClearCacheModal";
import RequestLogModal from "../../../../features/new-cars/debug-modal/components/RequestLogModal";

export const aemLexusDebugModalMap: ModalMapType<DebugModalIdsType> = {
    [MODAL_VERSION]: VersionModal,
    [MODAL_REQUEST_LOG]: RequestLogModal,
    [MODAL_CACHE_CLEAR]: ClearCacheModal,
    [MODAL_TOOLS]: ToolsModal,
};

export const aemLexusCommonModalMap: ModalMapType<CommonModalIdsType> = {
    ...aemLexusDebugModalMap,
    [MODAL_OK]: OkModal,
    [MODAL_CONTENT]: ContentModal,
    [MODAL_FINANCE_DISCLAIMER]: FinanceDisclaimerModal,
    [MODAL_ERRORS]: ErrorsModal,
};
