import styled from "styled-components";
import { theme } from "../../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import * as TooltipStyles from "../../../../../../../common-deprecated/styles/v2/lexus/components/Tooltip";
import * as Button from "../../../../../../../common-deprecated/styles/v2/lexus/components/Button";
import { IconWrapper } from "../../../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import { getDirection } from "../../../../../../../common-deprecated/themes/common";

export const PriceCollection = styled.span`
    display: flex;
    flex-direction: column;
`;

export const Cash = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 3rem;
    color: ${theme.colors.midnight};
    white-space: nowrap;

    ${TooltipStyles.PopoverButton} {
        margin-${getDirection("left")}: ${theme.space[1]}px;
        line-height: 2.4rem;
        .icon::before {
            position: relative;
            top: 0;
            color: ${theme.colors.black};
        }
    }

`;

export const CashDiscount = styled(Cash)`
    text-decoration: line-through;
`;

export const Monthly = styled.div`
    display: flex;
    align-items: center;
    max-width: 100%;
    font-family: ${theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 3rem;
    color: ${theme.colors.brushedSteel};

    ${TooltipStyles.TooltipButton} {
        align-items: center;
        color: ${theme.colors.brushedSteel};
    }

    ${TooltipStyles.PopoverButton} {
        display: flex;
        align-items: center;
        margin-${getDirection("left")}: 0;
        line-height: 2.4rem;
        .icon::before {
            position: relative;
            top: 0;
            font-size: 1.3rem;
            line-height: 2.4rem;
            color: ${theme.colors.brushedSteel};
        }
    }

    ${TooltipStyles.BoxWrapper} {
        span {
            white-space: normal;
        }
    }

    ${IconWrapper} {
        display: flex;
    }
`;

export const MonthlyPriceDisclaimer = styled.button`
    display: flex;
    align-items: center;
    padding: 0;
    background: transparent;
    border: none;
`;

export const DisclaimerIcon = styled.span`
    display: flex;
    align-items: center;
    color: ${theme.colors.grey7};
`;

export const MonthlyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const LegalText = styled.p`
    margin: 4px 0 0;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: left;
    text-indent: 0;
    color: ${theme.colors.grey7};

    ${Button.Link} {
        text-decoration: underline;
        color: ${theme.colors.grey7};
    }
`;
