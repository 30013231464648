import styled, { css } from "styled-components";
import { display, DisplayProps, space, SpaceProps, color, ColorProps } from "styled-system";
import { theme } from "../theme/lexusTheme";
import { TEXT_TRANSFORMS } from "../globals/Type";

type styleType = {
    textTransform?: (typeof TEXT_TRANSFORMS)[number];
};

type ButtonType = {
    disabled?: boolean;
    fullWidth?: boolean;
};

export const Button = styled.span.attrs<ButtonType & SpaceProps>((props) => ({
    className: `btn-text${props.disabled ? " disabled" : ""}`,
}))<ButtonType & SpaceProps>`
    max-width: 100%;
    ${space};
    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `};
`;

export const IconButton = styled.span.attrs<ButtonType & SpaceProps & DisplayProps>((props) => ({
    className: `btn-text t-base-text l-cta-text${props.disabled ? " disabled" : ""}`,
}))<ButtonType & SpaceProps & DisplayProps>`
    ${display};
    ${space};
    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `};
`;

export const Intro = styled.div.attrs((props) => ({
    className: "intro",
}))``;

export const Note = styled.div.attrs((props) => ({
    className: "note",
}))``;

/** @deprecated since Lexus revamp. Use `Text.Body`. */
export const Default = styled.div`
    font-family: ${theme.fonts.regular};
    font-size: 1.6rem;
    line-height: 2rem;
`;

export const Body = styled.div.attrs((props) => ({
    className: "l-body-text",
}))<ColorProps>`
    ${color};
`;

export const Legal = styled.span.attrs(() => ({
    className: "l-legal-text",
}))``;

export const Caption = styled.span.attrs(() => ({
    className: "l-caption-text",
}))``;

export const CTA = styled.span.attrs(() => ({
    className: "l-cta-text",
}))``;

export const Quote = styled.span.attrs(() => ({
    className: "l-quote-text",
}))``;

export const ListItem = styled.li.attrs(() => ({
    className: "l-listItem-text",
}))``;

export const Style = styled.span<styleType & SpaceProps>`
    ${space};
    ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
`;
