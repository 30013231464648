import React, { SyntheticEvent } from "react";
import * as ButtonStyles from "../../../../styles/v2/lexus/components/Button";
import * as Text from "../../../../styles/v2/lexus/utilities/Text";

type ButtonProps = {
    href?: string;
    onClick?: (event: SyntheticEvent) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    children: React.ReactNode;
    classNames?: string;
    linkWithoutIcon?: boolean;
    hasIcon?: boolean;
    "data-testid"?: string;
};

const ButtonPrimary = (props: ButtonProps): JSX.Element => {
    const { href, onClick, disabled, fullWidth, children, classNames, linkWithoutIcon, hasIcon } = props;
    return (
        <ButtonStyles.ButtonPrimary m={0} fullWidth={fullWidth} className={classNames} hasIcon={hasIcon}>
            <Text.Button
                as={href ? "a" : "button"}
                role={href ? "link" : "button"}
                href={href && !disabled ? href : undefined}
                type={!href ? "button" : undefined}
                fullWidth={fullWidth}
                onClick={onClick} // Even though links technically don't need onClicks, onClick can still be used for example for tracking or in cases where we have SPA behaviour but also support hrefs.
                className={[
                    classNames || "",
                    disabled ? "disabled" : "",
                    linkWithoutIcon ? "btn-external-link" : "",
                ].join(" ")}
                disabled={!href && disabled}
                aria-disabled={disabled || undefined}
                data-testid={props["data-testid"]}
            >
                {children}
            </Text.Button>
        </ButtonStyles.ButtonPrimary>
    );
};

export default ButtonPrimary;
