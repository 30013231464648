import styled from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { breakpoints } from "../../../../../shared-logic/themes/lexusTheme";
import * as FinanceToggle from "../../../../../../common-deprecated/styles/v2/lexus/components/FinanceToggle";
import * as FilterBackButton from "../../common/styles/FilterBackButtonStyles";
import * as FilterDropdownStyles from "./FilterDropdownStyles";

export const Wrapper = styled.div`
    padding-top: ${theme.space[2]}px;
    text-align: start;

    ${FilterDropdownStyles.Wrapper} {
        background: none;
    }

    ${FinanceToggle.Wrapper} {
        margin-bottom: ${theme.space[5]}px;
        @media screen and (min-width: ${breakpoints.lg}) {
            margin-top: -${theme.space[2]}px;
        }
    }

    ${FilterBackButton.Button} {
        padding-left: 0;
        padding-right: 0;
    }
`;
