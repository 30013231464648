import React, { useRef } from "react";
import { shallowEqual } from "react-redux";
import styled from "styled-components";
import * as Button from "../../../../../../common-deprecated/styles/v2/lexus/components/Button";
import useBrandModelFilter from "../../../../hooks/filters/useBrandModelFilter";
import { CarFilterLabel, useCarFilterLabel } from "../../../../utils/constants/labels";
import * as InputGroupStyle from "../../../../../../common-deprecated/features/filters/styles/InputGroup";
import { IconWrapper } from "../../../../../../common-deprecated/styles/v2/lexus/globals/IconWrapper";
import Icon from "../../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import { useCarFilterSelector } from "../../../../redux/store";
import FilteredModelList from "./BrandModelFilter/FilteredModelList";
import * as PanelScrollStyles from "./BrandModelFilter/styles/PanelScrollStyles";
import ModelFilterBody from "./BrandModelFilter/ModelFilterBody";
import { UscContext } from "../../../../../shared-logic/types/UscCommonTypes";

const DisclaimerWrapper = styled.div`
    text-align: center;
`;

const ModelFilter = (): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);
    const hasBrandSelected = useCarFilterSelector(
        (state) =>
            state.carFilters.currentFilter === UscContext.Used
                ? state.carFilters.usedCarBrand.values.some((brand) => brand.selected)
                : state.carFilters.stockCarBrand.values.some((brand) => brand.selected),
        shallowEqual,
    );

    const searchLabel = useCarFilterLabel("carFilterSearchBrandModels");

    const { searchQuery, searchActive, panelPage, clearSearch, onSearchInputChange } = useBrandModelFilter(inputRef);

    // Note that the input field is used for both general model and brand search as well as brand specific model search.
    return hasBrandSelected ? (
        <>
            <InputGroupStyle.Wrapper isActive>
                <input
                    type="text"
                    value={searchQuery}
                    placeholder={searchLabel}
                    onChange={onSearchInputChange}
                    ref={inputRef}
                />
                <InputGroupStyle.InputAppend>
                    {searchQuery ? (
                        <Button.Link type="button" onClick={clearSearch}>
                            <IconWrapper size="sm" iconColor="platinumDark">
                                <Icon variant="close" />
                            </IconWrapper>
                        </Button.Link>
                    ) : (
                        <IconWrapper size="md" iconColor="platinumDark">
                            <Icon variant="search" />
                        </IconWrapper>
                    )}
                </InputGroupStyle.InputAppend>
            </InputGroupStyle.Wrapper>
            {!searchActive && <ModelFilterBody />}

            {searchActive && (
                <PanelScrollStyles.Container>
                    <PanelScrollStyles.Wrapper currentPanel={panelPage} totalPanelCount={2}>
                        <PanelScrollStyles.ItemWrapper>
                            <PanelScrollStyles.Item>
                                <FilteredModelList query={searchQuery} />
                            </PanelScrollStyles.Item>
                        </PanelScrollStyles.ItemWrapper>
                    </PanelScrollStyles.Wrapper>
                </PanelScrollStyles.Container>
            )}
        </>
    ) : (
        <DisclaimerWrapper>
            <CarFilterLabel label="carFilterSelectBrandDisclaimer" />
        </DisclaimerWrapper>
    );
};

export default ModelFilter;
