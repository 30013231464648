import styled, { css } from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { getDirection } from "../../../../../../common-deprecated/themes/common";
import { breakpoints } from "../../../../../shared-logic/themes/lexusTheme";

export const Wrapper = styled.nav`
    --spacing-top: ${theme.space[3]}px;
    --spacing-bottom: 56px;
    display: flex;
    justify-content: center;
    margin: var(--spacing-top) 0 var(--spacing-bottom) 0;

    @media screen and (min-width: ${breakpoints.md}) {
        --spacing-top: ${theme.space[5]}px;
        --spacing-bottom: ${theme.space[6]}px;
    }
`;

export const PaginationList = styled.ul`
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    padding: 0;
    list-style: none;

    @supports not (gap: 0) {
        & > li:not(:nth-last-child(1)) {
            margin-${getDirection("right")}: 6px;
        }
    }
`;

export const Button = styled.button<{
    isActive?: boolean;
    isHidden?: boolean;
    hasOnlyIcon?: boolean;
    disabled?: boolean;
}>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: ${({ hasOnlyIcon }) => (hasOnlyIcon ? "8px" : "4px 8px")};
    font-family: ${theme.fonts.bold};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${theme.colors.midnight};
    background-color: ${({ hasOnlyIcon }) => (hasOnlyIcon ? theme.colors.silver : "transparent")};
    border-radius: 4px;

    &:is(:hover, :focus) {
        &,
        &:not([href]) {
            color: ${theme.colors.white};
            background-color: ${theme.colors.azure};
        }
    }

    ${({ isActive }) =>
        isActive &&
        css`
            &,
            &:not([href]) {
                color: ${theme.colors.white};
                background-color: ${theme.colors.azure};
            }
        `};
    ${({ isHidden }) =>
        isHidden &&
        css`
            visibility: hidden;
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `};
`;

export const Dots = styled.div`
    align-self: center;
    width: 36px;
    height: 36px;
    padding: 0 4px;
    font-family: ${theme.fonts.bold};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${theme.colors.midnight};
`;
