import styled, { css } from "styled-components";

/**
 * ScreenreaderOnly is a utility component that will hide content from the screen,
 * and make it visible only to screen readers.
 * Documentation: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
 * @example
 * export const ScreenreaderOnly = styled.span`
 *  ${screenreaderOnly};
 * `;
 */
export const srOnlyStyle = css`
    /* The ":not" portions of the selector are allowing a means for any focusable element
    to become visible when focused/active by a user. So elements that normally can't receive focus,
    like paragrpahs, will not become visible if a user navigates through content via screen reader controls or a Tab key,
    but natively focusable elements, or elements with a non-negative tabindex will have these elements appear in the DOM on focus. */
    &:not(:focus):not(:active) {
        position: absolute;
        width: 0.1rem;
        height: 0.1rem;
        white-space: nowrap;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
    }
`;

/**
 * Screenreader Only text
 * e.g. for hidden text in an icon (button)
 */
export const SrOnly = styled.span`
    ${srOnlyStyle};
`;

export default SrOnly;
