import React from "react";
import * as ButtonStyles from "../../../../styles/v2/lexus/components/Button";
import * as Text from "../../../../styles/v2/lexus/utilities/Text";

type ButtonProps = {
    link?: string;
    onClick?: (event: any) => void;
    disabled?: boolean;
    fullWidth?: boolean;
    linkTarget?: string;
    children: React.ReactNode;
    classNames?: string;
};

const ButtonTertiary = (props: ButtonProps): JSX.Element => {
    const { link, onClick, disabled, fullWidth, children, classNames, linkTarget } = props;
    return (
        <ButtonStyles.ButtonTertiary m={0} fullWidth={fullWidth}>
            <Text.Button
                as={link ? "a" : "button"}
                role={link ? "link" : "button"}
                target={linkTarget}
                href={link && !disabled ? link : undefined}
                type={!link ? "button" : undefined}
                onClick={onClick}
                className={[classNames || "", disabled ? "disabled" : ""].join(" ")}
                disabled={disabled && !link}
                aria-disabled={disabled || undefined}
                fullWidth={fullWidth}
            >
                <Text.Button className="t-base-text l-micro-text">{children}</Text.Button>
            </Text.Button>
        </ButtonStyles.ButtonTertiary>
    );
};

export default ButtonTertiary;
