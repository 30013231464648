import React from "react";
import ModalBaseView from "./ModalBaseView";
import * as Modal from "../../../../styles/v2/lexus/globals/Modal";
import Icon from "../../../../styles/v2/lexus/globals/Icon";
import { IconWrapper } from "../../../../styles/v2/lexus/globals/IconWrapper";
import * as Type from "../../../../styles/v2/lexus/globals/Type";
import * as TableStyles from "../../../../styles/v2/lexus/globals/Table";
import { ModalFinanceDisclaimerType } from "../../../../utils/modalConstants";
import { CommonLabel } from "../../../../utils/commonLabels";
import { ModalViewType } from "../../../../types/CommonTypes";
import SrOnly from "../../../../styles/v2/lexus/utilities/ScreenreaderOnly";

const FinanceDisclaimerModal = (props: ModalViewType<ModalFinanceDisclaimerType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { table, value } = modalSettings.disclaimer;

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="md">
            <Modal.Header>
                <Type.Strong>
                    <CommonLabel label="quotes" params={{ productName: modalSettings.productName }} />
                </Type.Strong>
                <Modal.Button type="button" onClick={close}>
                    <SrOnly>
                        <CommonLabel label="close" />
                    </SrOnly>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                {!!table && table.length > 0 && (
                    <TableStyles.Base>
                        {table.map((config) => {
                            return (
                                <TableStyles.TR key={config.name}>
                                    <TableStyles.TD>
                                        <Type.Text styleType="headline">{config.name}</Type.Text>
                                    </TableStyles.TD>
                                    <TableStyles.TD>
                                        <Type.Text dangerouslySetInnerHTML={{ __html: config.value }} />
                                    </TableStyles.TD>
                                </TableStyles.TR>
                            );
                        })}
                    </TableStyles.Base>
                )}
                <div dangerouslySetInnerHTML={{ __html: value }} />
            </Modal.Body>
        </ModalBaseView>
    );
};

export default FinanceDisclaimerModal;
