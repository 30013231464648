import styled, { css } from "styled-components";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Button = styled.button<{ direction: "left" | "right"; fullWidth?: boolean }>`
    display: flex;
    align-items: center;
    padding: ${theme.space[2]}px ${theme.space[4]}px;
    font-family: ${theme.fonts.bold};
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
    line-height: 2.2rem;
    text-transform: uppercase;
    color: ${theme.colors.azure};
    background-color: ${theme.colors.white};

    &:hover,
    &:focus {
        color: ${theme.colors.azureHover};
    }

    /* Fix position icon */
    .l-icon {
        position: relative;
        top: -2px;
    }

    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
            justify-content: space-between;
        `}
`;

export const IconLeftWrapper = styled.div`
    margin-right: ${theme.space[1]}px;
`;

export const IconRightWrapper = styled.div`
    margin-left: ${theme.space[1]};
`;
