import React from "react";
import ButtonSlider, { ButtonSliderType } from "./ButtonSlider";
import {
    getValueLabelRTLWrapper,
    mapButtonSliderMinMaxValuesToRTL,
    setValueRTLWrapper,
} from "../../../../../../../shared-logic/features/filters/utils/helpers";

/**
 * This is a wrapper to make to the ButtonSlider RTL
 */

const ButtonSliderRTL = (props: ButtonSliderType): JSX.Element => {
    const {
        minValue,
        maxValue,
        step,
        currentMaxValue,
        currentMinValue,
        setValue,
        setValueLabel,
        enableMinValue,
        order,
        getValueLabel,
        getSecondaryValueLabel,
        setSecondaryValueLabel,
        filterName,
    } = props;

    const { minRTL, maxRTL } = mapButtonSliderMinMaxValuesToRTL(minValue, maxValue, currentMinValue, currentMaxValue);

    return (
        <ButtonSlider
            minValue={minValue}
            maxValue={maxValue}
            step={step}
            currentMaxValue={maxRTL}
            currentMinValue={minRTL}
            setValue={setValueRTLWrapper(minValue, maxValue, currentMinValue || minValue, currentMaxValue, setValue)}
            enableMinValue={enableMinValue}
            order={order}
            getValueLabel={getValueLabelRTLWrapper(minValue, maxValue, getValueLabel)}
            setValueLabel={setValueLabel}
            getSecondaryValueLabel={getSecondaryValueLabel}
            setSecondaryValueLabel={setSecondaryValueLabel}
            filterName={filterName}
        />
    );
};
export default ButtonSliderRTL;
