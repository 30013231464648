import React, { ReactNode } from "react";
import FocusTrap from "focus-trap-react";
import { BreakPointValuesType } from "../../../../themes/common";
import useModalBaseView from "../../../../hooks/useModalBaseView";
import * as Styles from "../../../../styles/v2/lexus/globals/Modal";
import { ModalIdsType } from "../../../../types/CommonTypes";

type ModalBaseViewType = {
    modalIdType: ModalIdsType;
    onClose: () => void;
    onTransitionEnd?: () => void;
    show: boolean;
    children: ReactNode;
    size?: BreakPointValuesType;
    contentClass?: string;
    disableOutsideClick?: boolean;
    fullWidth?: boolean;
    isFullscreen?: boolean;
    hasFadeInAnimation?: boolean;
    focusTrap?: boolean;
};

/**
 * This wrapper should be used for every Modal component
 * It contains required modal wrappers and the overlay background.
 */
const ModalBaseView = (props: ModalBaseViewType): JSX.Element => {
    const {
        modalIdType,
        show,
        children,
        size,
        fullWidth,
        isFullscreen,
        onTransitionEnd,
        contentClass,
        onClose,
        disableOutsideClick,
        hasFadeInAnimation,
        focusTrap = true,
    } = props;
    const modalRef = useModalBaseView({ onClose, disableOutsideClick, show, modalIdType });
    return (
        <FocusTrap
            focusTrapOptions={{
                tabbableOptions: { getShadowRoot: () => false },
                allowOutsideClick: true,
            }}
            active={focusTrap}
        >
            <Styles.Wrapper role="dialog" isActive={show} onTransitionEnd={onTransitionEnd}>
                <Styles.Dialog
                    ref={modalRef}
                    data-cpdqm="ignore"
                    isActive={show}
                    hasFadeInAnimation={hasFadeInAnimation}
                    size={size}
                    isFullscreen={isFullscreen}
                    fullWidth={fullWidth}
                >
                    <Styles.Content className={contentClass}>{children}</Styles.Content>
                </Styles.Dialog>
            </Styles.Wrapper>
        </FocusTrap>
    );
};

export default ModalBaseView;
