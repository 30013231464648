import styled, { css } from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

/**
 * ScreenreaderOnly is a utility component that will hide content from the screen,
 * and make it visible only to screen readers.
 * Documentation: https://www.scottohara.me/blog/2017/04/14/inclusively-hidden.html
 * @example
 * export const SrOnly = styled.span`
 *  ${screenreaderOnly};
 * `;
 */
export const srOnlyStyle = css`
    &:not(:focus):not(:active) {
        position: absolute;
        width: 0.1rem;
        height: 0.1rem;
        white-space: nowrap;
        overflow: hidden;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
    }
`;

export const SrOnly = styled.span`
    ${srOnlyStyle};
`;

/**
 * focusStyle
 */
export const focusStyle = css`
    &:focus-visible {
        outline: 2px solid ${theme.colors.outline};
        outline-offset: -2px;
        box-shadow: inset 0 0 0 2px ${theme.colors.white};
    }

    @supports not selector(:focus-visible) {
        &:focus {
            outline: 2px solid ${theme.colors.outline};
            outline-offset: 2px;
        }
    }
`;
