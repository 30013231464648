import React, { useRef } from "react";
import { useCarFilterSelector } from "../../../../redux/store";
import { CarFilterLabel, sortOrderLabelMap, useCarFilterLabel } from "../../../../utils/constants/labels";
import * as Styles from "./styles/SortFiltersStyles";
import * as PopupListStyles from "./styles/PopupListStyles";
import { trackEvent } from "../../../../../../common-deprecated/utils/tracking";
import useSortFilters from "../../../../hooks/active-filters/useSortFilters";
import { getAemTrackingDefaults } from "../../../../utils/tracking";
import { IconChevronDown, IconSort } from "../../../../../../common-deprecated/styles/v2/lexus/globals/Icon";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/lexus/utilities/Ellipsis";
import * as StaticFilterBarStyles from "./styles/StaticFilterBarStyles";

const SortFilters = (): JSX.Element | null => {
    const popupRef = useRef<HTMLDivElement>(null);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const sortOrder = useCarFilterSelector(({ carResults }) => carResults.dfCarSortOrder);

    const buttonLabel = useCarFilterLabel(sortOrderLabelMap[sortOrder]);

    const itemTracking = (value: string): void => {
        trackEvent({
            ...getAemTrackingDefaults(currentFilter),
            action: "sort",
            label: value,
        });
    };

    const openTracking = (opened: boolean): void => {
        if (!opened) return;
        trackEvent({
            ...getAemTrackingDefaults(currentFilter),
            action: "open-sorting-menu",
        });
    };

    const { showPopup, values, togglePopup } = useSortFilters(popupRef, itemTracking, openTracking);

    if (!values || values.length <= 1) return null;

    return (
        <Styles.Wrapper>
            <StaticFilterBarStyles.Button
                hasIconRight
                onClick={togglePopup}
                isActive={showPopup}
                data-ignore-outside-clicks
            >
                <div>
                    <IconSort />
                    <Ellipsis maxWidth="100%">{buttonLabel}</Ellipsis>
                </div>
                <IconChevronDown />
            </StaticFilterBarStyles.Button>
            {showPopup && (
                <Styles.Popup ref={popupRef} data-testid="sort-popup">
                    {values.map(({ id, labelKey, selectFn }) => (
                        <PopupListStyles.ItemRow key={id}>
                            <PopupListStyles.Item width={1}>
                                <PopupListStyles.Button onClick={selectFn}>
                                    <CarFilterLabel label={labelKey} />
                                </PopupListStyles.Button>
                            </PopupListStyles.Item>
                        </PopupListStyles.ItemRow>
                    ))}
                </Styles.Popup>
            )}
        </Styles.Wrapper>
    );
};

export default SortFilters;
