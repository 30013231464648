import styled, { css } from "styled-components";
import { ColorType, getDirection } from "../../themes/common";
import { lexusTheme as theme } from "../../themes/lexusTheme";

/* general icon wrapper that can get a color */
export const IconWrapper = styled.span<{ iconColor?: ColorType }>`
    display: inline-block;
    line-height: 0.9;
    color: ${({ iconColor }) => (iconColor ? theme.color[iconColor] : theme.color.iconNeutral)} !important;
`;

export const ToggleIconWrapper = styled.div<{ isDown: boolean; iconColor?: ColorType }>`
    display: flex;
    position: relative;
    align-items: center;
    width: 20px;
    height: 20px;
    svg {
        position: absolute;
        transform: ${(props) => (props.isDown ? "rotate(180deg)" : "none")};
        transition: transform 0.3s, color 0.3s;
    }

    ${({ iconColor }) =>
        iconColor &&
        css`
            &,
            svg {
                color: ${theme.color[iconColor]};
            }
        `};
`;

export const CtaIconWrapper = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-${getDirection("right")}: 10px;
    color: ${theme.color.lightest};
    background-color: ${theme.color.primary};
    border-radius: 50%;
`;

export default IconWrapper;
