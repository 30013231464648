import styled from "styled-components";
import { theme } from "../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";

export const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-family: ${theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-transform: none;
    color: ${theme.colors.azure};
`;

export const ModalFooter = styled.div`
    position: sticky;
    bottom: 0;
    padding: ${theme.space[2]}px;
    background-color: ${theme.colors.white};
    border-top: 1px solid ${theme.colors.platinumLight};
`;

export const ListSortFilter = styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin: 0;
        padding: ${theme.space[2]}px;
        border-bottom: 1px solid ${theme.colors.platinumLight};
    }
`;
