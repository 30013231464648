import styled from "styled-components";
import { theme } from "../theme/lexusTheme";
import { bodyStyle, legalStyle } from "../globals/Type";

export const Wrapper = styled.div`
    margin: ${theme.space[3]}px 0;
    padding-top: ${theme.space[3]}px;
    color: ${theme.colors.midnight};
    border-top: 1px solid ${theme.colors.platinumLight};
`;

export const Title = styled.div`
    margin-bottom: ${theme.space[2]}px;
    text-transform: uppercase;
    color: ${theme.colors.midnight};
    ${bodyStyle};
`;

export const Text = styled.div`
    margin-bottom: ${theme.space[2]}px;
    color: ${theme.colors.midnight};
    ${legalStyle};
`;

/**
 * DisclaimersWrapper is used as wrapper for disclaimers on container pages like PDPContainer etc...
 */
export const DisclaimersWrapper = styled.div`
    &:not(:empty) {
        width: 100%;
    }
`;
