import React from "react";
import ModalBaseView from "./ModalBaseView";
import { ModalOkType } from "../../../../utils/modalConstants";
import * as Modal from "../../../../styles/v2/lexus/globals/Modal";
import * as Text from "../../../../styles/v2/lexus/utilities/Text";
import SrOnly from "../../../../styles/v2/lexus/utilities/ScreenreaderOnly";
import { IconWrapper } from "../../../../styles/v2/lexus/globals/IconWrapper";
import Icon from "../../../../styles/v2/lexus/globals/Icon";
import ButtonPrimary from "../../../aem/lexus/button/ButtonPrimary";
import { ModalViewType } from "../../../../types/CommonTypes";
import { CommonLabel } from "../../../../utils/commonLabels";

// Basic modal with a title and an ok button that closes the modal.
const OkModal = (props: ModalViewType<ModalOkType>): JSX.Element => {
    const { show, close, modalSettings } = props;
    const { contentLabel, titleLabel, okLabel } = modalSettings;

    return (
        <ModalBaseView modalIdType={modalSettings.type} onClose={close} show={show} size="md">
            <Modal.Header>
                <Modal.Title>{titleLabel}</Modal.Title>
                <Modal.Button type="button" onClick={close}>
                    <SrOnly>
                        <CommonLabel label="close" />
                    </SrOnly>
                    <IconWrapper aria-hidden="true">
                        <Icon variant="close" />
                    </IconWrapper>
                </Modal.Button>
            </Modal.Header>
            <Modal.Body>
                <Text.Body>{contentLabel}</Text.Body>
            </Modal.Body>
            <Modal.Actions>
                <ButtonPrimary onClick={close}>{okLabel}</ButtonPrimary>
            </Modal.Actions>
        </ModalBaseView>
    );
};

export default OkModal;
