import styled, { css } from "styled-components";
import * as CheckboxStyle from "../../../../../../common-deprecated/styles/v2/lexus/components/Input";
import { theme } from "../../../../../../common-deprecated/styles/v2/lexus/theme/lexusTheme";
import { bodyStyle } from "../../../../../../common-deprecated/styles/v2/lexus/globals/Type";
import { getDirection } from "../../../../../../common-deprecated/themes/common";

// eslint-disable-next-line prefer-destructuring

export const Checkbox = styled(CheckboxStyle.Checkbox)<{ disabled?: boolean }>`
    ${bodyStyle};

    &::before {
        width: 32px;
        height: 32px;
        background-color: ${theme.colors.platinumLight};
    }
    ${(props) =>
        props.disabled &&
        css`
            color: ${theme.colors.platinumDark};
            cursor: not-allowed;
            &::before {
                background-color: ${theme.colors.mist};
                border: none;
            }
        `}
`;

export const Wrapper = styled(CheckboxStyle.Wrapper)`
    input:checked ~ ${Checkbox} {
        &::before {
            width: 32px;
            height: 32px;
            background-color: ${theme.colors.midnight};
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.353553' y1='4.415' x2='3.58459' y2='7.64604' stroke='white'/%3E%3Cline x1='2.89122' y1='7.63409' x2='10.4303' y2='0.633505' stroke='white'/%3E%3C/svg%3E%0A");
        }
    }
    label {
        padding-top: 6px;
        padding-left: 42px;
    }
`;

export const Text = styled.span<{ srOnly?: boolean }>`
    ${({ srOnly }) =>
        srOnly &&
        css`
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            white-space: nowrap;
            border: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            clip-path: inset(50%);
        `};
`;

export const Counter = styled.span.attrs(() => ({
    className: "l-nano-text",
}))<{ disabled?: boolean }>`
    display: inline-block;
    margin-${getDirection("left")}: ${theme.space[2]}px;
    color: ${({ disabled }) => (disabled ? theme.colors.platinumDark : theme.colors.titanium)};
`;
