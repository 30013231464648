import styled, { css } from "styled-components";
import { space, SpaceProps } from "styled-system";
import { getDirection, getBreakpoint } from "../../../../themes/common";
import { theme } from "../theme/lexusTheme";

const CUSTOM_INPUT_WIDTH = 20;
const CUSTOM_INPUT_HEIGHT = 20;

const Input = styled.div``;

const radioLeftStyles = css`
    padding-${getDirection("left")}: ${theme.space[3]}px;
    &::before {
        ${getDirection("left")}: 0;
    }
    &::after {
        ${getDirection("left")}: ${theme.space[1]}px;
    }
`;

const radioRightStyles = css`
    padding-${getDirection("right")}: ${theme.space[3]}px;
    &::before {
        ${getDirection("right")}: 0;
    }
    &::after {
        ${getDirection("right")}: ${theme.space[1]}px;
    }
`;

export const Radio = styled(Input)<{ checked?: boolean; disabled?: boolean; radioRight?: boolean }>`
    position: relative;
    margin: ${theme.space[1]}px 0;
    transition: opacity 0.3s;
    cursor: pointer;
    opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};

    &::before {
        position: absolute;
        top: 8px;
        width: 8px;
        height: 8px;
        content: "";
        background: ${({ checked }) => (checked ? theme.colors.midnight : "none")};
        border: 2px solid ${({ checked }) => (checked ? theme.colors.midnight : theme.colors.platinumLight)};
        border-radius: 50%;
    }

    ${({ radioRight }) =>
        radioRight
            ? css`
                  @media ${getBreakpoint("down", "sm")} {
                      ${radioLeftStyles};
                  }
                  @media ${getBreakpoint("up", "sm")} {
                      ${radioRightStyles};
                  }
              `
            : radioLeftStyles};
`;

export const Label = styled.label`
    font-weight: 400;
    cursor: pointer;
`;

export const Checkbox = styled.label.attrs<{ isDark?: boolean; hasSmallText?: boolean }>(() => ({
    className: "custom-control-label",
}))<{ disabled?: boolean; isDark?: boolean; hasSmallText?: boolean }>`
    display: inline;
    position: relative;
    margin: 0;
    padding: 0;
    padding-${getDirection("left")}: 36px;
    font-family: ${theme.fonts.book};
    font-size: 1.8rem;
    line-height: 2.4rem;
    cursor: pointer;

    ${({ hasSmallText }) =>
        hasSmallText &&
        css`
            font-size: 1.3rem;
            line-height: 2.4rem;
        `};

    /* checkbox square */
    &::before {
        position: absolute;
        top: 2px;
        ${getDirection("left")}: 0;
        width: ${CUSTOM_INPUT_WIDTH}px;
        height: ${CUSTOM_INPUT_HEIGHT}px;
        content: "";
        background-color: ${({ disabled }) => (disabled ? theme.colors.silver : theme.colors.platinumLight)};
        border: 1px solid ${({ disabled }) => (disabled ? theme.colors.silver : theme.colors.platinumLight)};
        border-radius: 0 !important;
        box-sizing: border-box;

        ${({ isDark, disabled }) =>
            isDark &&
            css`
                color: ${disabled ? theme.colors.midnight : theme.colors.white};
                background-color: ${disabled ? theme.colors.silver : theme.colors.platinumLight};
                border: 1px solid ${disabled ? theme.colors.silver : theme.colors.platinumLight};
            `};
    }
`;

type InputType = {
    variant: "checkbox" | "radio";
};

export const Wrapper = styled.div.attrs<InputType>((props) => ({
    className: `custom-${props.variant}`,
}))<SpaceProps & InputType>`
    ${space};

    input {
        display: none;
    }

    input:checked ~ ${Checkbox} {
        &::before {
            position: absolute;
            top: 2px;
            ${getDirection("left")}: 0;
            width: ${CUSTOM_INPUT_WIDTH}px;
            height: ${CUSTOM_INPUT_HEIGHT}px;
            color: ${theme.colors.white};
            content: "";
            background-color: ${theme.colors.steel};
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.729549' y1='5.2685' x2='3.92955' y2='8.4685' stroke='white' stroke-width='1.5'/%3E%3Cline x1='2.86811' y1='8.46967' x2='10.0681' y2='1.26967' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            border-color: ${theme.colors.steel};
        }
    }
`;

export default Input;
