import styled, { css } from "styled-components";
import { space, SpaceProps, display, DisplayProps } from "styled-system";

export const Hr = styled.hr<SpaceProps & DisplayProps>`
    display: block;
    width: 100%;
    border: 0;
    ${({ theme }) => css`
        border-bottom: 1px solid ${theme.color.grey2};
    `};
    ${display};
    ${space};
`;

export const Light = styled(Hr)`
    border-bottom-color: ${({ theme }) => theme.color.grey4};
`;

export default Hr;
